<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata" width="99%" height="100%" type="bar"
			:options="chartoptions" :series="chartdata"></apexchart>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "RFNPlusChart",
	components: {},
	props: {
		sourcedata: Array,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			chartoptions: {
				chart: {
					id: "N Plus Reach",
					toolbar: {
						show: true
					},
					animations: {
						enabled: true
					},
					type: 'bar',
					background: '#F9F9F9'
				},
				plotOptions: {
					bar: {
						horizontal: false,
						endingShape: 'rounded',
						columnWidth: '80%',
					},
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: false
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
					labels: {
						formatter: function(value) {
							let val = Math.abs(value)
							if(val >= 1000) {
								val = (val / 1000).toFixed(0) + 'k'
							}
							return val
						}
					}
				},
				colors: ['#3669B0'],
				dataLabels: {
					enabled: false
				},
			},
		}
	},
	computed: {
		chartdata() {
			let dat = []
			for(let s of this.sourcedata) {
				dat.push({x: s.spot+'+', y: s.nPlusReach})
			}
			return [{
				name: 'R&F n+ reach',
				data: dat
			}]
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>