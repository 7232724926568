<template>
	<div class="menubar">
		<div class="menu-insides">
			<div style="display: flex; align-items: center;">
				<router-link to="/">
					<img src="@/assets/audology-rev.svg" alt="Logo" class="top-logo" title="Click to start new report" @click="unsetReportType" />
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "MenuBarExternal",
	components: {

	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {

	},
	watch: {},
	mounted() {

	}
}
</script>
<style>

</style>

<style scoped>
.menubar {
	background-color: var(--bluedark);
	margin: 0;
	color: #FFF;
	font-size: 12px;
	font-family: 'Roboto';
	font-weight: bold;
	top: 0;
	z-index: 997;
	position: fixed;
	width: 100%;
	height: 40px;
}
.menu-insides {
	display: flex;
	height: 100%;
	align-content: center;
}
.top-logo {
	margin-top: 3px;
	height: 24px;
	width: 160px;
	cursor: pointer;
}

</style>