<template>
	<div class="tab" :class="activeClass" @click="setActiveTab(title)">
		<span>{{title}}</span>
		<span v-if="showTick" class="tickholder">
			<i class="mdi mdi-check-bold" title="Valid selection"></i>
		</span>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "TabItem",
	components: {},
	mixins: [globalMixin],
	props: {
		title: String,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		activeTab() {
			return this.store.activeTab;
		},
		activeClass() {
			if(this.activeTab === this.title) return 'active';
			return 'inactive';
		},
		showTick() {
			let at = this.title;
			if(at === 'Markets' && this.store.selectionObs.markets.length > 0) return true;
			else if(at === 'Surveys' && this.store.selectionObs.surveys.length > 0) return true;
			else if(at === 'Stations' && (this.store.selectionObs.stations.length > 0 )) return true;
			else if(at === 'Demographics' && this.store.selectionObs.demographics.length > 0) return true;
			else if(at === 'Dayparts' && this.store.selectionObs.dayparts.length > 0) return true;
			else if(at === 'Statistics' && this.store.selectionObs.statistics.length > 0) return true;
			else if(at === 'Schedule' && this.store.rfTotalSpots > 0) return true;
			return false;
		},
	},
	methods: {

	},
	watch: {},
	mounted() {

	}
}
</script>
<style>
li.dropdown:focus-visible {
	outline: none;
}
li.dropdown, li.dropdown a {
	outline: none !important;
}
</style>
<style scoped>
.tab {
	border-right: none;
	border-left: none;
	border-bottom: 1px solid transparent;
	width: 140px;
	padding: 12px 0 9px 0;
	margin-right: 10px;
	bottom: 0;
	font-size: 13px;
	font-family: Roboto;
	font-weight: normal;
	text-transform: none;
	cursor: pointer;
	border-radius: 5px 5px 0 0;
	text-align: center;
	height: 36px;
	position: relative;
	transition: all 0.3s ease-in-out;
}
.tab.inactive {
	background-color: var(--bluelight);
	color: #FFF;
	border: none;
}
.tab.inactive:hover {
	background-color: var(--bluelighthover);
	color: #FFF;
}
.tab.active {
	background-color: var(--greybg);
	color: var(--bluedark);
	background-image: none;
}
.tickholder {
	position: absolute;
	right: 10px;
}
.tickholder i {
	color: var(--greenmain);
	font-size: 14px;
}

@media screen and (max-width: 1150px) {
	.tab {
		width: 100px;
		font-size: 12px;
	}
}

</style>