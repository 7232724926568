<template>
	<div class="stationcombo-summary">
		<div class="popup-topbar">
			<span class="boldme">{{stnob.name}}</span>
			<span class="closer" @click="$emit('close')">
					<i class="mdi mdi-close"></i>
				</span>
		</div>
		<div class="popup-insides">
			<div>
				<div v-if="logourl" class="item-logo">
					<img :src="logourl" />
				</div>
			</div>
			<p v-if="stnob.isSnapshotCombo === true" style="margin-bottom: 10px; font-size: 12px; font-style: italic;">
				This station list contains all stations that are or ever were in the combo.
				When results are run on a specific survey, only stations that were in the combo at the time are used.
			</p>
			<p v-if="market"><span class="bold">Market:</span> {{market.name}}</p>
			<p v-if="market"><span class="bold">TV Agg:</span> {{getTVAggMktName(market.tvAggId)}}</p>
			<p class="api-info">API ID: {{stnob.id}}</p>
			<p class="boldme" style="margin: 15px 0 10px;">Stations In Combo</p>
			<div class="station-list">
				<p v-for="st in stations" :key="st.id" style="line-height: 1.4; font-size: 13px;">{{st.name}}{{getMarketFromStation(st)}}</p>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "StationComboPopover",
	components: {},
	mixins: [globalMixin],
	props: {
		stnob: Object,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		market() {
			let m = this.store.markets.find(item => item.id === this.stnob.mktid);
			if(m) return m;
			return false;
		},
		stations() {
			if(this.stnob.stationIDsArr && this.stnob.stationIDsArr.length > 0) {
				return this.store.stations.filter(item => this.stnob.stationIDsArr.includes(item.id));
			}
			return []
		},
		logourl() {
			if(this.stnob.logofile.length > 0) {
				return this.store.logosRoot + "station-logos/" + this.stnob.logofile;
			}
			else return false;
		},
	},
	methods: {
		getMarketFromStation(st) {
			let mkt = this.store.markets.find(item => item.id === st.mktid);
			if(mkt) return ", " + mkt.name + " " + mkt.state;
			return '';
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.item-logo {
	display: inline-block;
	margin-bottom: 20px;
}
.item-logo img {
	width: 100px;
	height: 100px;
	object-fit: contain;
	margin-right: 5px;
}
.stationcombo-summary {
	display: flex;
	background-color: #FFF;
	overflow-y: auto;
	height: 100%;
}
</style>