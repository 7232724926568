<template>
	<div class="survey-group" :class="{activegroup : isactive, isfolder : grp.isFolder === true}" @click.stop="setActiveGroup">
		<div class="sgheadname">
			<div class="sub">
				<div>
					<span class="nmediter">{{grp.name}}</span>
					<i v-if="grp.isFolder === true" class="mdi mdi-pencil-outline" title="Edit survey group name" @click="startNameEdit(grp)"></i>
				</div>
				<div>
					<i class="mdi mdi-close rem" title="Delete survey group" @click="$emit('delete')"></i>
				</div>
			</div>
		</div>

		<div v-if="grp.isFolder === true">
			<div v-for="(element, index) in grp.survobs" class="survey-group-sub" :key="index">
				<div>
					<i class="mdi mdi-circle-medium"></i>
					{{element.name}}
				</div>
				<div>
					<i class="mdi mdi-close rem" @click="removeFromGroup(element.id)" title="Remove survey from this group"></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "SurveyGroup",
	components: {

	},
	props: {
		grpin: Object,
		isactive: Boolean,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			grp: {},
		}
	},
	computed: {

	},
	methods: {
		setActiveGroup() {
			if(this.grp.isFolder === true) {
				this.$emit('activegroup', this.grp.id)
			}
		},
		removeFromGroup(survid) {
			this.grp.survobs = this.grp.survobs.filter(item => item.id !== survid)
		},
		startNameEdit(grp) {
			let g = JSON.parse(JSON.stringify(grp))
			let p = window.prompt('Enter new name for this group:', g.name)
			if(p !== null && p !== false) {
				this.grp.name = p
				this.grp.customName = true
			}
		},
		updateInternal() {
			this.grp = JSON.parse(JSON.stringify(this.grpin))
		},
	},
	watch: {
		grp: {
			deep: true,
			handler() {
				if(this.grp.survobs) {
					let usedSids = []
					let sidlen = this.grp.survobs.length
					while (sidlen--) {
						let mysid = this.grp.survobs[sidlen].id
						if (usedSids.includes(mysid)) this.grp.survobs.splice(sidlen, 1)
						else usedSids.push(mysid)
					}
					if (this.grp.survobs.length > 1) {
						//check only one survey per group per Xtra market
						let xtraMktsThisGroup = []
						let subs = []
						let needsChange = false
						for (let survob of this.grp.survobs) {
							if (survob.isGfk === false && xtraMktsThisGroup.includes(survob.mktid)) {
								needsChange = true
							}
							else {
								xtraMktsThisGroup.push(survob.mktid)
								subs.push(survob)
							}
						}
						if(needsChange) {
							this.showTempDangerAlert('Only one survey per market for Xtra surveys can be added to a Survey Group.  You can add Xtra surveys for the same market separately.', 10)
							this.grp.survobs = subs
						}
					}
				}
				this.$emit('change', this.grp)
			}
		},
	},
	mounted() {
		this.updateInternal()
	}
}
</script>

<style scoped>
.survey-group {
	padding: 5px 10px;
	cursor: move;
}
.survey-group.isfolder {
	background-color: var(--greylight);
	margin-bottom: 4px;
}
.survey-group.activegroup {
	background-color: var(--greenmain);
}
.sgheadname {
	font-weight: bold;
	font-size: 14px;
	display: flex;
	align-items: center;
}
.survey-group.isfolder .sgheadname {
	margin-bottom: 5px;
}
.sgheadname .sub {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.sgheadname .mdi {
	cursor: pointer;
	margin: 0 5px;
}
.sgheadname .mdi-pencil-outline:hover {
	color: #888;
}
.survey-group-sub {
	padding: 2px 15px;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.nmediter {
	padding: 3px 5px;
	border: none;
}
.nmediter.editing {
	background-color: #FFF;
}
.rem {
	cursor: pointer;
	font-size: 14px;
}
.rem:hover {
	color: var(--rederror)
}
</style>