<template>
	<div>
		<div>
			<div v-if="logourl" class="item-logo">
				<img :src="logourl" />
			</div>
			<div v-if="replogo" class="item-logo">
				<img :src="replogo" />
			</div>
		</div>
		<p class="api-info">API ID: {{stnob.id}}</p>
		<br/>
		<p><span class="bold">Market:</span> {{market.name}}</p>
		<p><span class="bold">TV Agg:</span> {{getTVAggMktName(market.tvAggId)}}</p>
		<p v-if="repString.length > 0"><span class="bold">Rep Company:</span> {{repString}}</p>
		<div v-if="stnob.usage">
			<p v-if="stnob.usage.dateOldest !== '0000-00-00'">
				<span class="bold">First Active:</span> {{stnob.usage.dateOldest}}
			</p>
			<p v-if="stnob.usage.dateNewest !== '0000-00-00'">
				<span class="bold">Most Recently Active:</span> {{stnob.usage.dateNewest}}
			</p>
			<p v-if="stnob.usage.stationNameDates">
				<span class="bold">Historical Names:</span><br/>
				<span v-for="nameob in stnob.usage.stationNameDates" :key="nameob.name">{{nameob.name}}<br/>{{nameob.oldest}} to {{nameob.newest}}<br/></span>
				<br/>
			</p>
		</div>
<!--		<p v-if="ownershipString.length > 0"><br/>{{ownershipString}}</p>-->
<!--		<p v-if="repString.length > 0" v-html="repString"></p>-->
<!--		<p v-if="stnob.outsidemkt === true"><br/>Overflow station from outside market</p>-->
<!--		<p v-if="stnob.community === true"><br/>Non-public results</p>-->
		<br/>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "StationPopover",
	components: {},
	mixins: [globalMixin],
	props: {
		stnob: Object,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		market() {
			return this.store.markets.find(item => item.id === this.stnob.mktid);
		},
		logourl() {
			if(this.stnob.logofile && this.stnob.logofile.length > 0) {
				return this.store.logosRoot + "station-logos/" + this.stnob.logofile;
			}
			else if(this.stnob.netgroupown && this.stnob.netgroupown.length > 0) { //looking for a network value
				return this.store.logosRoot + "station-logos/"+this.stnob.netgroupown +".png";
			}
			else return false;
		},
		replogo() {
			if(this.stnob.repnetgroup && this.stnob.repnetgroup.length > 0) {
				return this.store.logosRoot + "station-logos/"+this.stnob.repnetgroup +".png";
			}
			return false;
		},
		ownershipString() {
			let str = '';
			if(this.stnob.netgroupown.length > 0) {
				str += this.convertNetworkString(this.stnob.netgroupown);
				// let sub = this.convertNetworkString(this.stnob.subnetown);
				// if(sub.length > 0) {
				// 	str += ": " + sub;
				// }
			}
			return str;
		},
		repString() {
			let str = '';
			if(this.stnob.repnetgroup.length > 0) {
				str += this.convertNetworkString(this.stnob.repnetgroup);
				// let sub = this.convertNetworkString(this.stnob.repsubnet)
				// if(sub.length > 0) {
				// 	str += " - " + sub;
				// }
			}
			// if(str.length > 0) {
			// 	str = '<br/><span class="bold">Rep Company:</span><br/> ' + str;
			// }
			return str;
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.item-logo {
	display: inline-block;
}
.item-logo img {
	width: 100px;
	height: 60px;
	object-fit: contain;
	margin-right: 5px;
	margin-bottom: 10px;
}
</style>