<template>
	<div style="padding: 20px 40px 20px 0;">
		<div v-if="store.user.isadmin === 1">
			Query failed<br/><br/>
			{{store.lookupQueryFailedMessage}}
		</div>
		<div v-else>
			Unfortunately, the query you submitted failed.
			<br/><br/>
			This could be a bug at our end, or temporary server overload, or your query contained too many surveys/stations/demos/dayparts/spots and it timed out.
			<br/><br/>
			We have logged this error.
			<br/><br/>
			Please try again and if the issue persists please contact us at support@audology.app
			<br/><br/>
			If you have a lot of surveys/stations/demos/dayparts/spots, you can try to reduce the number of selections and try again.
			<br/><br/>
		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "QueryErrorNote",
	components: {},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>
<style scoped></style>