<template>
	<div>
		<table v-if="summarydata" class="std-tbl">
			<tbody>
				<tr>
					<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Spots</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(summarydata.nSpots)}}</td></tr>
				<tr>
					<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Impacts</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(summarydata.impacts,0)}}</td>
				</tr>
				<tr>
					<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Gross Rating Pct</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(summarydata.grossRating,1)}}</td>
				</tr>
				<tr>
					<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cume000s Total Reach</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(summarydata.reach,0)}}</td></tr>
				<tr>
					<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CumePct Total Reach Pct</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(summarydata.reachPct,1)}}</td>
				</tr>
				<tr>
					<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Average Frequency</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(summarydata.avgFrequency,1)}}</td>
				</tr>
				<tr>
					<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Average Audience</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(summarydata.avgAud,0)}}</td>
				</tr>
				<tr>
					<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Universe Estimate</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(summarydata.universeEstimate,0)}}</td>
				</tr>
				<tr v-if="summarydata.cost > 0">
					<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Total Cost</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(summarydata.cost)}}</td>
				</tr>
				<tr v-if="summarydata.cost > 0">
					<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{summarydata.cpm}}</td>
				</tr>
<!--				<tr v-if="summarydata.sumDailyRchPct && summarydata.sumDailyRchPct > 0">-->
<!--					<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">4 Sum Daily Reach Pct</td>-->
<!--					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(summarydata.sumDailyRchPct, 1)}}</td>-->
<!--				</tr>-->
<!--				<tr v-if="summarydata.weeklyCumePct && summarydata.weeklyCumePct > 0">-->
<!--					<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">5 Weekly Cume Pct</td>-->
<!--					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(summarydata.weeklyCumePct, 1)}}</td>-->
<!--				</tr>-->
			</tbody>
		</table>

		<BlankExcelTwoRows></BlankExcelTwoRows>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlankExcelTwoRows from "@/components/result-tables/BlankExcelTwoRows";

export default {
	name: "RFSummaryTable",
	components: {BlankExcelTwoRows},
	props: {
		summarydata: Object
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>