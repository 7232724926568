<template>
	<div>
		<div style="font-size: 12px; text-transform: uppercase; padding: 5px 0; margin-top: 10px; font-weight: bold;" class="">
			<span v-if="folderExpanded" @click="folderExpanded = false" style="cursor:pointer;"><i class="mdi mdi-minus"></i></span>
			<span v-else @click="folderExpanded = true"><i class="mdi mdi-plus" style="cursor:pointer;"></i></span>
			{{folderob.name}}
		</div>
		<div v-for="dayp in filteredDaypartsInFolder" :key="dayp.id" class="checklist-item">
			<div v-if="folderExpanded" class="ch-and-title" @click="$emit('toggledaypart', dayp)">
				<span class="toggler">
					<i v-if="store.selectionObs.dayparts.some(item => item.id === dayp.id)" class="mdi mdi-check-bold"></i>
				</span>
				<span class="label" :id="'dayp'+dayp.id" :title="'API ID: '+dayp.id">{{dayp.name}}</span>
				<div class="dp-edit-hold" v-if="dayp.owner === store.user.id" @click.stop="editDaypart(dayp.id)" title="Edit daypart">
					<i class="mdi mdi-pencil-outline"></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "DaypartViewFolder",
	components: {},
	mixins: [globalMixin],
	props: {
		dayparts: Array,
		folderob: Object,
	},
	data: function () {
		return {
			store: store,
			showingFolderChooser: false,
			chosenFolderId: 0,
			activeDaypartId: 0,
			folderExpanded: true,
		}
	},
	computed: {
		filteredDaypartsInFolder() {
			return this.dayparts.filter(item => item.infolder === this.folderob.id);
		},
	},
	methods: {

	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>