<template>
	<div>
		<div>
			<input type="file" @change="handleFile" id="inputFileElement" />
		</div>

		<div v-if="file1" style="margin-top: 10px;">
			<span v-if="file1" class="primary-button" @click="submitGSCSCV(0)" >
				<span v-if="!isUploading && store.rfConfig.schedule.length > 0">Replace Existing</span>
				<span v-else-if="!isUploading && store.rfConfig.schedule.length === 0">Submit</span>
				<span v-else-if="isUploading">Working...</span>
			</span>

			<span v-if="file1 && store.rfConfig.schedule.length > 0" class="primary-button" @click="submitGSCSCV(1)" style="margin-left: 20px;">
				<span v-if="!isUploading">Merge With Existing</span>
				<span v-else-if="isUploading">Working...</span>
			</span>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "ScheduleGSCSVUploader",
	components: {

	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			file1: null,
			isUploading: false,

		}
	},
	computed: {},
	methods: {
		handleFile(evt) {
			if(evt.target.files.length) {
				this.file1 = evt.target.files[0]
			}
		},
		submitGSCSCV(merge) {
			if(this.file1 && this.isUploading === false) {
				this.isUploading = true
				let formData = new FormData()
				formData.append('usercreds', JSON.stringify(this.store.usercreds))
				formData.append('csv-file-upload', this.file1, this.file1.name)
				formData.append('merge', merge)
				formData.append('existingSchedule', JSON.stringify(this.store.rfConfig.schedule))
				let targ = '/post/upload-gs-csv-schedule.php'
				let self = this
				axios.post(targ, formData).then(function (response) {
					self.file1 = null //reset to null for this
					document.getElementById('inputFileElement').value = null
					let ret = response.data //response.data is returned info
					if (ret.error === 1) {
						self.showKalert(ret.resp)
					}
					else if(ret.schedule) {
						if(ret.schedule.length > 0) {
							self.store.selectionObs.markets = self.store.markets.filter(item => ret.markets.includes(item.id))
							self.store.selectionObs.surveys = [
								{id: 'gr_1', name: 'R&F Group', isFolder: true, survobs: self.store.surveys.filter(item => ret.surveys.includes(item.id))}
							]
							self.store.selectionObs.stations = self.store.stations.filter(item => ret.stations.includes(item.id))
							self.store.rfConfig.type = 'goldstdcsv'
							self.store.rfConfig.schedule = ret.schedule
							self.store.rfConfig.ratecard = {}
							self.store.rfConfig.ratecard_id = null
							if(self.store.selectionObs.demographics.length === 0) {
								self.store.selectionObs.demographics = self.store.demographics.filter(item => item.id === 48)
							}
						}
					}
					self.isUploading = false
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>

</style>