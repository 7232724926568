<template>
	<div>
		<table v-if="store.rfConfig.schedule.length > 0" class="std-tbl">
			<thead>
				<tr>
					<td></td>
					<td class="alignleft">Station</td>
					<td>Spot Date</td>
					<td>Spot Time</td>
					<td>Spot Duration</td>
					<td>Radio Type</td>
					<td>Cost</td>
					<td>Spot Type</td>
					<td>Desc</td>
					<td></td>
				</tr>
			</thead>
			<draggable tag="tbody" item-key="index" v-model="storeschedActivePaginated" handle=".handle">
				<template #item="{element, index}">
					<tr :class="{editingRow : editingRowIndex === index}">
						<td class="icnholdrow"><i class="mdi mdi-drag handle"></i></td>
						<td>
							<RFStationChooser v-if="index === editingRowIndex" :inval="element.stationId" v-on:update="element.stationId = $event"></RFStationChooser>
							<span v-else>{{getStationNameFromId(element.stationId)}}</span>
						</td>
						<td>
							<input v-if="index === editingRowIndex" type="text" class="rfinputfield" v-model="element.playDate" style="width: 100px;" />
							<span v-else>{{element.playDate}}</span>
						</td>
						<td>
							<RFQtrHrChooser v-if="index === editingRowIndex" :inval="element.playTime" v-on:update="element.playTime = $event"></RFQtrHrChooser>
							<span v-else>{{element.playTime}}</span>
						</td>
						<td>
							<input v-if="index === editingRowIndex" type="number" class="rfinputfield num" v-model="element.spotDuration" />
							<span v-else>{{element.spotDuration}}</span>
						</td>
						<td>
							<RFAudioTypeChooser v-if="index === editingRowIndex" :inval="element.audioType" v-on:update="element.audioType = $event" :station="element.stationId"></RFAudioTypeChooser>
							<span v-else>{{getAudioTypeNameFromId(element.audioType)}}</span>
						</td>
						<td>
							<input v-if="index === editingRowIndex" type="number" class="rfinputfield num" v-model="element.spotRate" />
							<span v-else>{{element.spotRate}}</span>
						</td>
						<td>
							<input v-if="index === editingRowIndex" type="text" class="rfinputfield" v-model="element.spotClass" />
							<span v-else>{{element.spotClass}}</span>
						</td>
						<td>
							<input v-if="index === editingRowIndex" type="text" class="rfinputfield" v-model="element.campaign" />
							<span v-else>{{element.campaign}}</span>
						</td>
						<td class="icnholdrow">
							<i class="mdi mdi-pencil-outline" title="Edit spot" @click="editRow(index)"></i>
							<i class="mdi mdi-content-copy" title="Clone row to end of table" @click="cloneRow(index)"></i>
							<i class="mdi mdi-close" title="Delete row" @click="deleteRowFromSchedule(index)"></i>
						</td>
					</tr>
				</template>
			</draggable>
		</table>
		<div v-if="maxPages > 1" style="margin: 20px 0;">
			<span style="margin-right: 10px; font-size: 12px;">Page</span>
			<select v-model="activePage">
				<option v-for="n in maxPages" :key="n" :value="n-1">{{n}}</option>
			</select>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import RFStationChooser from "@/components/rf/RFStationChooser.vue";
import draggable from "vuedraggable";
import RFQtrHrChooser from "@/components/rf/RFQtrHrChooser.vue";
import RFAudioTypeChooser from "@/components/rf/RFAudioTypeChooser.vue";

export default {
	name: "RFGoldStdCSVScheduleEditor",
	components: {
		RFAudioTypeChooser,
		RFQtrHrChooser,
		draggable,
		RFStationChooser
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			displayRowLimit: 100,
			activePage: 0,
		}
	},
	computed: {
		storesched: {
			get() {
				return this.store.rfConfig.schedule
			},
			set(newob) {
				this.store.rfConfig.schedule = newob
			}
		},
		paginateStart() {
			return this.activePage * this.displayRowLimit
		},
		paginateEnd() {
			return this.paginateStart + this.displayRowLimit
		},
		maxPages() {
			return Math.ceil(this.store.rfConfig.schedule.length / this.displayRowLimit)
		},
		storeschedActivePaginated: {
			get() {
				return this.store.rfConfig.schedule.slice(this.paginateStart, this.paginateEnd)
			},
			set(newob) {
				this.store.rfConfig.schedule = newob
			}
		},
		editingRowIndex: {
			get() {
				return this.store.rfScheduleEditRowIndex
			},
			set(newval) {
				this.store.rfScheduleEditRowIndex = newval
			}
		}
	},
	methods: {
		editRow(ind) {
			if(this.editingRowIndex === ind) this.editingRowIndex = null
			else this.editingRowIndex = ind
		},
		deleteRowFromSchedule(ind) {
			this.store.rfConfig.schedule.splice(ind, 1)
		},
		cloneRow(ind) {
			let lr = JSON.parse(JSON.stringify(this.store.rfConfig.schedule[ind])) //create a deep copy
			this.store.rfConfig.schedule.push(lr)
			this.editingRowIndex = this.store.rfConfig.schedule.length - 1 //mark the newly added row as the one being edited
		},
	},
	watch: {
		storesched() {
			if(this.storesched.length < this.paginateEnd) {
				this.activePage = 0
			}
		},
	},
	mounted() {
		this.editingRowIndex = null
	}
}
</script>

<style scoped>
.std-tbl tbody td {
	padding: 10px 10px;
	text-align: left;
}
.std-tbl tbody .editingRow td:not(.icnholdrow) {
	padding: 0;
}
</style>