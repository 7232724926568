<template>
	<div>
		<select @change="$emit('update', selectval)" v-model="selectval" class="selectbox">
			<option v-for="c in choices" :value="c" :key="c">{{ucfirst(c)}}</option>
		</select>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "RFDayOfWeekChooser",
	components: {},
	props: {
		inval: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			choices: [
				'sun','mon','tue','wed','thu','fri','sat'
			],
			selectval: null,
		}
	},
	computed: {},
	methods: {
		updateInternal() {
			this.selectval = this.inval
		}
	},
	watch: {
		inval() {
			this.updateInternal()
		}
	},
	mounted() {
		this.updateInternal()
	}
}
</script>

<style scoped>

</style>