<template>
	<div>
		<table class="excel-table-info">
			<tbody>
				<tr>
					<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">N+ Spots Exposure</td>
				</tr>
				<tr><td></td><td></td></tr>
			</tbody>
		</table>

		<table class="std-tbl">
			<thead>
				<tr>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="bold">At least N Spots</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="bold">Excl %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="bold">n+ %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="bold">Excl Reach</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="bold">n+ Reach</td>
<!--					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="bold">UE</td>-->
				</tr>
			</thead>
			<tbody>
				<tr v-for="mk in tdata" :key="mk.spot">
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.spot}}+</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(mk.exclPct,1)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(mk.nPlusPct,1)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(mk.exclReach,0)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(mk.nPlusReach,0)}}</td>
<!--					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(ue,1)}}</td>-->
				</tr>
			</tbody>
		</table>

		<BlankExcelTwoRows></BlankExcelTwoRows>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlankExcelTwoRows from "@/components/result-tables/BlankExcelTwoRows";

export default {
	name: "RFNPlusExposureTable",
	components: {BlankExcelTwoRows},
	props: {
		tdata: Array,
		ue: Number,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>