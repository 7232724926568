<template>
	<div class="warnbox">
		<p v-for="(w, ind) in warnings" :key="ind">* {{w}}</p>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "WarningsBox",
	components: {},
	props: {
		warnings: Array
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.warnbox {
	background-color: var(--greymid);
	padding: 10px;
	margin: 15px 0;
	width: calc(100% - 30px);
}
.warnbox p {
	font-size: 12px;
}
</style>