<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">{{title}}</span>
				<span class="closer" @click="$emit('close')">
					<i class="mdi mdi-close"></i>
				</span>
			</div>
			<div class="popup-insides">
				<div>
					<textarea ref="pasteTextHolder" v-model="pastedText" placeholder="Paste your content from Excel/CSV here" style="height: 300px;"></textarea>
				</div>
				<div>
					<span @click="saveData" class="primary-button" style="margin-right: 10px;">Submit</span>
					<span @click="$emit('close')" class="primary-button cancel-button">Cancel</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "PasteWindowModal",
	components: {},
	props: {
		title: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			pastedText: '',
		}
	},
	computed: {},
	methods: {
		saveData() {
			this.$emit('receive', this.pastedText)
		},
	},
	watch: {},
	mounted() {
		this.pastedText = ''
		this.$refs.pasteTextHolder.focus()
	}
}
</script>

<style scoped>
textarea {
	width: 96%;
	padding: 10px;
	margin-bottom: 10px;
}
</style>