<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Share Report Link</span>
				<span class="closer" @click="$emit('close')">
					<i class="mdi mdi-close"></i>
				</span>
			</div>
			<div class="popup-insides" style="text-align: left;">
				<div>
					To share this report with others, copy then email the link below to your recipients:<br/><br/>
					<span style="font-size: 12px;">https://platform.audology.com.au/share/{{store.shareableLinkSlug}}</span>
					<br/><br/>
					<a class="text-link" :href="'https://platform.audology.com.au/share/' + store.shareableLinkSlug" target="_blank">Preview in new tab</a>
					<br/><br/><br/>
					If you wish the recipient to open the report on the Summary Table by default use this link below:<br/><br/>
					<span style="font-size: 12px;">https://platform.audology.com.au/share/{{store.shareableLinkSlug}}?view=summary-table</span>
					<br/><br/>
					<a class="text-link" :href="'https://platform.audology.com.au/share/' + store.shareableLinkSlug + '?view=summary-table'" target="_blank">Preview in new tab</a>

				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: "ShareReportLink",
	components: {

	},
	mixins: [globalMixin],
	props: {

	},
	data: function () {
		return {
			store: store,
			fetching: false,
			filterText: '',
		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.popup-container {
	height: 300px;
}

</style>