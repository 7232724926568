<template>
	<div>
		<table class="std-tbl rfschedtbl">
			<thead>
				<tr>
					<td></td>
					<td>Station</td>
					<td>Week Number</td>
					<td>Day Of Week</td>
					<td>Qtr Hour Start</td>
					<td>Radio Type</td>
					<td>Cost</td>
					<td>Ratecard Rate</td>
					<td>Spot Type</td>
					<td></td>
				</tr>
			</thead>
			<draggable tag="tbody" item-key="index" v-model="store.rfConfig.schedule" handle=".handle">
				<template #item="{element, index}">
					<tr :class="{editingRow : editingRowIndex === index}">
						<td class="icnholdrow"><i class="mdi mdi-drag handle"></i></td>
						<td>
							<RFStationChooser v-if="index === editingRowIndex" :inval="element.stationId" v-on:update="element.stationId = $event"></RFStationChooser>
							<span v-else>{{getStationNameFromId(element.stationId)}}</span>
						</td>
						<td>
							<input v-if="index === editingRowIndex" type="number" class="rfinputfield num" v-model="element.weekNumber" />
							<span v-else>{{element.weekNumber}}</span>
						</td>
						<td>
							<RFDayOfWeekChooser v-if="index === editingRowIndex" :inval="element.dayOfWeek" v-on:update="element.dayOfWeek = $event"></RFDayOfWeekChooser>
							<span v-else>{{ucfirst(element.dayOfWeek)}}</span>
						</td>
						<td>
							<RFQtrHrChooser v-if="index === editingRowIndex" :inval="element.playTime" v-on:update="element.playTime = $event"></RFQtrHrChooser>
							<span v-else>{{element.playTime}}</span>
						</td>
						<td>
							<RFAudioTypeChooser v-if="index === editingRowIndex" :inval="element.audioType" v-on:update="element.audioType = $event" :station="element.stationId"></RFAudioTypeChooser>
							<span v-else>{{getAudioTypeNameFromId(element.audioType)}}</span>
						</td>
						<td>
							<input v-if="index === editingRowIndex" type="number" class="rfinputfield num" v-model="element.spotRate" />
							<span v-else>{{element.spotRate}}</span>
						</td>
						<td>
							<input v-if="index === editingRowIndex" type="number" class="rfinputfield num" v-model="element.ratecardRate" />
							<span v-else>{{element.ratecardRate}}</span>
						</td>
						<td>
							<input v-if="index === editingRowIndex" type="text" class="rfinputfield" v-model="element.spotClass" />
							<span v-else>{{element.spotClass}}</span>
						</td>
						<td class="icnholdrow">
							<i class="mdi mdi-pencil-outline" title="Edit spot" @click="editRow(index)"></i>
							<i class="mdi mdi-content-copy" title="Clone row to end of table" @click="cloneRow(index)"></i>
							<i class="mdi mdi-close" title="Delete row" @click="deleteRowFromSchedule(index)"></i>
						</td>
					</tr>
				</template>
			</draggable>
		</table>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import RFStationChooser from "@/components/rf/RFStationChooser";
import RFDayOfWeekChooser from "@/components/rf/RFDayOfWeekChooser";
import RFQtrHrChooser from "@/components/rf/RFQtrHrChooser";
import RFAudioTypeChooser from "@/components/rf/RFAudioTypeChooser";
import draggable from "vuedraggable";

export default {
	name: "RFQtrHrScheduleEditor",
	components: {
		RFAudioTypeChooser,
		RFQtrHrChooser,
		RFDayOfWeekChooser,
		RFStationChooser,
		draggable
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {
		editingRowIndex: {
			get() {
				return this.store.rfScheduleEditRowIndex
			},
			set(newval) {
				this.store.rfScheduleEditRowIndex = newval
			}
		}
	},
	methods: {
		editRow(ind) {
			if(this.editingRowIndex === ind) this.editingRowIndex = null
			else this.editingRowIndex = ind
		},
		deleteRowFromSchedule(ind) {
			this.store.rfConfig.schedule.splice(ind, 1)
		},
		cloneRow(ind) {
			let lr = JSON.parse(JSON.stringify(this.store.rfConfig.schedule[ind])) //create a deep copy
			this.store.rfConfig.schedule.push(lr)
			this.editingRowIndex = this.store.rfConfig.schedule.length - 1 //mark the newly added row as the one being edited
		},
	},
	watch: {
		'store.rfConfig.schedule': {
			deep: true
		},
	},
	mounted() {
		if(this.store.rfConfig.schedule.length === 0) this.addBlankRowToSchedule()
	}
}
</script>

<style scoped>
.std-tbl tbody td {
	padding: 10px 10px;
	text-align: left;
}
.std-tbl tbody .editingRow td:not(.icnholdrow) {
	padding: 0;
}
</style>