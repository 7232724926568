<template>
	<div>
		<TopTabBar>
			<template v-slot:belowtabs>
				<div class="flex" style="height: 100%;">
					<span class="freq-shortcut" @click="shortcutDemos('clear')">Clear</span>
<!--					<span class="freq-shortcut" @click="shortcutDemos('occ')">Occupations</span>-->
				</div>
			</template>
		</TopTabBar>
		<div class="body-hold">
			<div class="view-holder nostyle">
				<ListsChooser v-on:filterupdate="filterText = $event">
					<template v-slot:interior_tabs>
						<InteriorTab id="global" name="Global" :isactive="demogroupactive === 'global'" @set="demogroupactive = $event"></InteriorTab>
						<InteriorTab id="survey" name="Survey" :isactive="demogroupactive === 'survey'" @set="demogroupactive = $event"></InteriorTab>
						<InteriorTab id="user" name="User" :isactive="demogroupactive === 'user'" @set="demogroupactive = $event"></InteriorTab>
					</template>
					<template v-slot:left_items>
						<div v-if="demogroupactive === 'global'">
							<p class="boldme grouphead" style="margin-top: 0;">All People</p>
							<DemoViewGroup :demos="filterGlobalDemosAllPeople" :showfolders="false" v-on:toggledemo="toggleDemoInSelection($event)"></DemoViewGroup>
							<p class="boldme grouphead">Males</p>
							<DemoViewGroup :demos="filterGlobalDemosMales" :showfolders="false" v-on:toggledemo="toggleDemoInSelection($event)"></DemoViewGroup>
							<p class="boldme grouphead">Females</p>
							<DemoViewGroup :demos="filterGlobalDemosFemales" :showfolders="false" v-on:toggledemo="toggleDemoInSelection($event)"></DemoViewGroup>
							<p class="boldme grouphead">Other</p>
							<DemoViewGroup :demos="filterGlobalDemosOther" :showfolders="false" v-on:toggledemo="toggleDemoInSelection($event)"></DemoViewGroup>
						</div>
						<div v-else-if="demogroupactive === 'survey'">
							<div v-for="dem in filteredSurveySpecificGlobalDemos" :key="dem.id" class="checklist-item">
								<div class="ch-and-title" @click="toggleDemoInSelection(dem)">
									<span class="toggler">
										<i v-if="store.selectionObs.demographics.some(item => item.id === dem.id)" class="mdi mdi-check-bold"></i>
									</span>
									<span class="label" :id="'dem'+dem.id" :title="'API ID: '+dem.id">{{dem.name}}</span>
								</div>
							</div>
						</div>
						<div v-else-if="demogroupactive === 'user'">
							<div>
								<span class="primary-button" @click="createNewDemo" >
									<i class="mdi mdi-plus"></i> Create New
								</span>
								<span class="primary-button" @click="store.demosManageOverlayShown = true" style="margin-left:10px;">
									<i class="mdi mdi-cog"></i> Manage
								</span>
							</div>
							<DemoViewGroup :demos="filteredUserSavedAllSurveyDemos" v-on:toggledemo="toggleDemoInSelection($event)"></DemoViewGroup>
							<p class="boldme grouphead">
								User Survey Specific
								<i class="mdi mdi-plus-box-outline" @click="createNewDemo" style="cursor:pointer;" title="New Demographic"></i>
							</p>
							<DemoViewGroup :demos="filteredSurveySpecificUserDemos" v-on:toggledemo="toggleDemoInSelection($event)"></DemoViewGroup>
						</div>
					</template>
					<template v-slot:rightside>
						<draggable v-model="mylist" item-key="index" v-bind="dragOptions">
							<template #item="{index}">
								<SortableInternal type="demographic" :objid="mylist[index].id" v-on:remove="toggleDemoInSelection(mylist[index])"></SortableInternal>
							</template>
						</draggable>
					</template>
				</ListsChooser>
			</div>
		</div>
	</div>
</template>

<script>
import SortableInternal from "@/components/SortableInternal";
import DemoViewGroup from "@/components/demos/DemoViewGroup";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import draggable from 'vuedraggable'
import TopTabBar from "@/components/TopTabBar.vue";
import ListsChooser from "@/components/views/ListsChooser.vue";
import InteriorTab from "@/components/InteriorTab.vue";
export default {
	name: "DemographicsView",
	components: {
		InteriorTab,
		ListsChooser,
		TopTabBar,
		DemoViewGroup,
		SortableInternal,
		draggable
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			filterText: '',

			demogroupactive: 'global',
		}
	},
	computed: {
		mylist: {
			get() {
				return this.store.selectionObs.demographics;
			},
			set(value) {
				this.store.selectionObs.demographics = value;
			}
		},
		dragOptions() {
			return {
				animation: 200,
				disabled: false,
				ghostClass: "ghost-drag"
			}
		},
		reportType() {
			return this.store.reportType;
		},
		surveyCodesChosen() {
			let sl = [];
			for(let s of this.store.selectionObs.surveys) {
				for(let ss of s.survobs) {
					sl.push(ss.id)
				}
			}
			return sl
		},
		filteredGlobalDemos() {
			let mydemos = this.store.demographics.filter(item => (item.userid === 0 && !item.survey_code));
			if(this.filterText.length === 0) return mydemos;
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demographics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filterGlobalDemosAllPeople() {
			return this.filteredGlobalDemos.filter(item => item.name.toLowerCase().startsWith('p '));
		},
		filterGlobalDemosMales() {
			return this.filteredGlobalDemos.filter(item => item.name.toLowerCase().startsWith('m '));
		},
		filterGlobalDemosFemales() {
			return this.filteredGlobalDemos.filter(item => item.name.toLowerCase().startsWith('f '));
		},
		filterGlobalDemosOther() {
			return this.filteredGlobalDemos.filter(item => !item.name.toLowerCase().startsWith('m ') && !item.name.toLowerCase().startsWith('f ') && !item.name.toLowerCase().startsWith('p '));
		},
		filteredUserSavedAllSurveyDemos() {
			let mydemos = this.store.demographics.filter(item => (item.userid === this.store.user.id && !item.survey_code));
			if(this.filterText.length === 0) return mydemos;
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demographics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSurveySpecificGlobalDemos() {
			let mydemos = this.store.demographics.filter(item => (item.userid === 0 && this.surveyCodesChosen.includes(item.survey_code)));
			if(this.filterText.length === 0) return mydemos;
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demographics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSurveySpecificUserDemos() {
			let mydemos = this.store.demographics.filter(item => (item.userid === this.store.user.id && this.surveyCodesChosen.includes(item.survey_code)));
			if(this.filterText.length === 0) return mydemos;
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demographics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
	},
	methods: {
		shortcutDemos(val) { //clear, occ
			let sels = [];
			if(val === 'clear') {
				sels = [];
			}
			// else {
			// 	for(let i=1102; i<=1129; i++) {
			// 		sels.push(this.store.demographics.find(item => item.id === i));
			// 	}
			// }
			this.store.selectionObs.demographics = sels;
		},
	},
	watch: {},
	mounted() {
		document.documentElement.style.scrollBehavior = 'auto';
		setTimeout(() => window.scrollTo(0, 0), 5); //tiny timeout to let page load first
	}
}
</script>

<style scoped>
.view-holder {
	margin: 0 20px 20px;
	padding: 0;
}
.grouphead {
	font-size: 16px;
	margin: 20px 0 10px;
}
</style>