<template>
	<div class="rtblHolder">
<!--		<div style="margin-bottom: 20px;">-->
<!--			<p v-for="header in headers" :key="header" v-html="header"></p>-->
<!--		</div>-->

		<table class="excel-table-info">
			<tbody>
			<tr v-for="(header, ind) in headers" :key="ind">
				<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="boldme" v-html="header"></td>
			</tr>
			<tr><td></td><td></td></tr>
			</tbody>
		</table>

		<table class="std-tbl">
			<thead>
				<tr>
					<td></td>
					<td v-for="(ci, index) in columnItems" :key="index" v-html="ci"
						data-f-bold="true" data-fill-color="FFEAEAEA"
						data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10"
					></td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(ri, index) in rowItems" :key="index">
					<td v-for="(rii, index) in ri" :key="index"
						:data-t="getExcelDataType(rii)"
						data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10"
					>{{numdisplayKeepExisting(rii)}}</td>
				</tr>
			</tbody>
		</table>

		<ColourHighlightToggler></ColourHighlightToggler>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ColourHighlightToggler from "@/components/result-tables/ColourHighlightToggler";
export default {
	name: "ResultTableBasic",
	components: {
		ColourHighlightToggler

	},
	mixins: [globalMixin],
	props: {
		objin: Object,
	},
	data: function () {
		return {
			store: store,
			showAllRowsTogglerRf: true,
		}
	},
	computed: {
		columnItems() {
			return this.objin.colnames;
		},
		rowItems() {
			return this.objin.rows;
		},
		headers() {
			return this.objin.header;
		},
		returnedData() {
			return this.store.returnedData;
		},
	},
	methods: {

	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.std-tbl tbody tr:last-of-type.notshown {
	display: table-row;
}
</style>