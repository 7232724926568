<template>
	<div style="margin-bottom: 20px;">
		<div v-if="spotplays && spotplays.length > 0">
			<table class="std-tbl">
				<thead>
					<tr>
						<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Spot Number</td>
						<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Week Number</td>
						<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="ctr">Market</td>
						<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="ctr">Station</td>
						<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Day Of Week</td>
						<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Std Daypart</td>
						<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Radio Type</td>
						<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Play Date</td>
						<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Play Time</td>
	<!--					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Play Time / Qtr Hour</td>-->
						<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Spot Type</td>
	<!--					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Reach / Impacts</td>-->
						<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(sp, ind) in activePaginatedSpotplays" :key="ind">
						<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{ind+1}}</td>
						<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.weekNumber}}</td>
						<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{getMarketNameFromId(sp.marketId)}}</td>
						<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.stationName}}</td>
						<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{ucfirst(sp.dayOfWeek)}}</td>
						<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.simple_daypart}}</td>
						<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{getAudioTypeNameFromId(sp.audioType)}}</td>
						<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.playDate}}</td>
						<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.playTime}}</td>
	<!--					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.qtrhr_start_time}}</td>-->
						<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.spotClass}}</td>
	<!--					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(getRFQtrHrAudForSpotRow(sp))}}</td>-->
						<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.spotRate}}</td>
					</tr>
				</tbody>
			</table>
			<div v-if="maxPages > 1" style="margin: 20px 0;">
				<span style="margin-right: 10px; font-size: 12px;">Page</span>
				<select v-model="activePage">
					<option v-for="n in maxPages" :key="n" :value="n-1">{{n}}</option>
				</select>
			</div>
		</div>
		<div v-else>
			<p>No spots to show in list yet.  Add some lines to your campaign above then click Generate Spots</p>
		</div>
		<BlankExcelTwoRows></BlankExcelTwoRows>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlankExcelTwoRows from "@/components/result-tables/BlankExcelTwoRows";

export default {
	name: "RFSpotScheduleTablePre",
	components: {BlankExcelTwoRows},
	props: {
		spotplays: Array,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			displayRowLimit: 100,
			activePage: 0,
		}
	},
	computed: {
		paginateStart() {
			return this.activePage * this.displayRowLimit
		},
		paginateEnd() {
			return this.paginateStart + this.displayRowLimit
		},
		maxPages() {
			return Math.ceil(this.spotplays.length / this.displayRowLimit)
		},
		activePaginatedSpotplays() {
			return this.spotplays.slice(this.paginateStart, this.paginateEnd)
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>