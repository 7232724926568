<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="bar"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartLoaderInsert from "@/components/charts/ChartLoaderInsert";
export default {
	name: "ApexStackedBar",
	components: {
		ChartLoaderInsert
	},
	props: {
		chartdata: Array,
		chartcolors: Array,
		categories: Array,
		fetching: Boolean,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chartoptions: {
				chart: {
					id: "ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'bar',
					stacked: true,
					stackType: '100%',
				},
				plotOptions: {
					bar: {
						horizontal: true,
						endingShape: 'rounded',
						columnWidth: '80%',
					},
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: false
					},
					axisTicks: {
						show: true
					}
				},
				tooltip: {
					enabled: true,
					y: {
						formatter: function (val) {
							return val + "%"
						}
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
					forceNiceScale: true,
				},
				// colors: [],
				dataLabels: {
					enabled: false
				},
			},
		}
	},
	computed: {
		chartcols() {
			if(this.chartcolors && this.chartcolors.length > 0) return this.chartcolors;
			return this.store.defaultColors;
		},
	},
	methods: {
		updateColours() {
			let outcols = this.store.defaultColors;
			if(this.chartcolors && this.chartcolors.length > 0) {
				outcols = this.chartcolors;
			}
			this.chartoptions.colors = outcols;
			// this.chartoptions = {...this.chartoptions, ...{
			// 		colors: outcols
			// 	}};
		},
	},
	watch: {
		chartcolors() {
			this.updateColours();
		}
	},
	mounted() {
		this.chartoptions.xaxis.categories = this.categories
		this.updateColours();
	}
}
</script>

<style scoped>

</style>