<template>
	<div>
		<table v-if="splitdata" class="std-tbl">
			<thead>
			<tr>
				<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Network</td>
				<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">N Spots</td>
				<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Impacts</td>
				<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">GRPs</td>
				<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cume</td>
				<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cume %</td>
				<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Frequency</td>
				<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Aud</td>
				<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
				<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
			</tr>
			</thead>
			<tbody>
			<tr v-for="co in rowdata" :key="co.id">
				<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{co.name}}</td>
				<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(co.nSpots)}}</td>
				<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(co.impacts)}}</td>
				<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(co.grossRating,1)}}</td>
				<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(co.reach)}}</td>
				<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(co.reachPct,1)}}</td>
				<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(co.avgFrequency,1)}}</td>
				<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(co.avgAud, 0)}}</td>
				<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(co.cost)}}</td>
				<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{co.cpm}}</td>
			</tr>
			</tbody>
		</table>
		<BlankExcelTwoRows></BlankExcelTwoRows>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlankExcelTwoRows from "@/components/result-tables/BlankExcelTwoRows";

export default {
	name: "RFNetworkComboResultTable",
	components: {BlankExcelTwoRows},
	props: {
		splitdata: Object,
		marketid: Number,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {
		rowdata() {
			let rowdata = []
			for(let cid in this.splitdata) {
				let rt = this.splitdata[cid]
				let r = rt.summary
				if(this.marketid > 0) {
					r = {}
					let ms = rt.marketSplits.find(m => m.marketId === this.marketid && m.audioType === rt.audioType)
					if (ms) r = ms
				}
				r.id = cid
				let cob = this.store.stationcombos.find(c => c.id === r.id)
				if(cob) {
					r.name = cob.name
					rowdata.push(r)
				}
			}
			return rowdata
		},
	},
	methods: {

	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>