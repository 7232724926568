<template>
	<div style="width: 100%; max-width: 1200px;">
		<QueryErrorNote v-if="store.lookupQueryFailed === true"></QueryErrorNote>
		<div v-else>
			<div style="margin-bottom: 20px;">
				<select v-model="chosenSurveyId" class="selectbox">
					<option v-for="surv in surveyOptions" :key="surv.id" :value="surv.id">{{surv.name}}</option>
				</select>
				<select v-model="chosenDemoId" class="selectbox">
					<option v-for="dem in demoOptions" :key="dem.id" :value="dem.id">{{dem.name}}</option>
				</select>
				<select v-model="chosenDaypartId" class="selectbox">
					<option v-for="dp in daypartOptions" :key="dp.id" :value="dp.id">{{dp.name}}</option>
				</select>
				<select v-model="chosenStationId" class="selectbox">
					<option v-for="st in stationOptions" :key="st.id" :value="st.id">{{st.name}}</option>
				</select>
			</div>

			<div class="pies-holder">
				<ChartHolderDash :height="360" addclasses="half"
					charttitle="P1/P2 Cume Split"
					:source="srcStr+'Percent of station cume in daypart that are '+stnName+' P1s/P2s'"
				>
					<template v-slot:default>
						<StationPie v-if="stationDataset" :series="p1CumeSplitData.series" :labels="p1CumeSplitData.labels"></StationPie>
					</template>
				</ChartHolderDash>
				<ChartHolderDash :height="360" addclasses="half"
					charttitle="P1/P2 Listening Volume Split"
					:source="srcStr+'Percent of amount of station listening in daypart from '+stnName+' P1s/P2s'"
				>
					<template v-slot:default>
						<StationPie v-if="stationDataset" :series="p1ListenSplitData.series" :labels="p1ListenSplitData.labels"></StationPie>
					</template>
				</ChartHolderDash>
			</div>

			<div>
				<ChartHolderDash :height="360"
					:charttitle="'Where else are '+stnName+' Cumers listening?'"
					:source="srcStr+'Percent of '+stnName+' Cume that listens to each station in Daypart - where else are '+stnName+' Cumers listening?'"
				>
					<template v-slot:default>
						<ApexFreqBar v-if="stationDataset" :chartdata="pctCumeOthersChart" :fetching="false"></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash :height="360"
					:charttitle="'Where else are '+stnName+' P1s listening?'"
					:source="srcStr+'Percent of '+stnName+' P1s that listens to each station in Daypart'"
				>
					<template v-slot:default>
						<ApexFreqBar v-if="stationDataset" :chartdata="pctP1sCumeOthersChart" :fetching="false"></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash :height="360"
					:charttitle="'What percentage '+stnName+' Cumers listening is given to each station for the selected daypart?'"
					:source="srcStr+'How much (volume) listening (percent) in daypart is given to each station by '+stnName+' Cumers'"
				>
					<template v-slot:default>
						<ApexFreqBar v-if="stationDataset" :chartdata="pctListenToOtherStationsByCume" :fetching="false"></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash :height="360"
					:charttitle="'What percentage '+stnName+' P1s listening is given to each station for the selected daypart?'"
					:source="srcStr+'How much (volume) listening (percent) in daypart is given to each station by '+stnName+' P1s'"
				>
					<template v-slot:default>
						<ApexFreqBar v-if="stationDataset" :chartdata="pctListenToOtherStationsByP1" :fetching="false"></ApexFreqBar>
					</template>
				</ChartHolderDash>

			</div>

			<ChartHolderDash v-if="stationDataset && stationDataset.quintiles" :height="360"
				charttitle="Volume of listening by quintile"
				:source="srcStr+'Percent of station listening by each 20% of listeners (heaviest station listeners to lightest) in daypart. ' +
				'Each quintile has the same number of listeners. Quintile 1 is the heaviest listeners, quintile 5 is the lightest listeners.'"
			>
				<template v-slot:default>
					<ApexStackedBar v-if="stationDataset && stationDataset.quintiles" :chartdata="stationDataset.quintiles" :categories="['Quintiles']" :fetching="false"></ApexStackedBar>
				</template>
			</ChartHolderDash>

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import QueryErrorNote from "@/components/QueryErrorNote.vue";
import StationPie from "@/components/charts/StationPie.vue";
import ApexFreqBar from "@/components/charts/ApexFreqBar.vue";
import ChartHolderDash from "@/components/charts/ChartHolderDash.vue";
import ApexStackedBar from "@/components/charts/ApexStackedBar.vue";

export default {
	name: "P1ReportPage",
	components: {
		ApexStackedBar,
		ChartHolderDash,
		ApexFreqBar,
		StationPie,
		QueryErrorNote,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			chosenDemoId: null,
			chosenSurveyId: null,
			chosenDaypartId: null,
			chosenStationId: null,

		}
	},
	computed: {
		stnob() {
			if(this.chosenStationId) {
				return this.store.stations.find(item => item.id === this.chosenStationId)
			}
			return null
		},
		stnName() {
			if(this.stnob) {
				return this.stnob.name
			}
			return 'Station'
		},
		srcStr() {
			let mysurv = this.store.surveys.find(item => item.id === this.chosenSurveyId)
			let mydemo = this.store.demographics.find(item => item.id === this.chosenDemoId)
			let mydp = this.store.dayparts.find(item => item.id === this.chosenDaypartId)
			if(mydp && mydemo && mysurv) {
				return mysurv.name + ', ' + mydemo.name + ', ' + mydp.name + '. '
			}
			return ''
		},
		p1CumeSplitData() {
			if(this.stationDataset && this.stnob) {
				return this.stationDataset.p1CumeSplit
			}
			return null
		},
		p1ListenSplitData() {
			if(this.stationDataset && this.stnob) {
				return this.stationDataset.p1ListenSplit
			}
			return null
		},
		pctCumeOthersChart() {
			let ser = {name: 'Percent of Cume', data: []}
			let counter = 0
			for(let dp of this.stationDataset.pctCumeOtherStations) {
				if(counter < 10) {
					let stob = this.store.stations.find(item => item.id === dp.x)
					if(stob) dp.x = stob.name
					ser.data.push(dp)
				}
				counter++
			}
			return [ser]
		},
		pctP1sCumeOthersChart() {
			let ser = {name: 'Percent of P1s', data: []}
			let counter = 0
			for(let dp of this.stationDataset.pctCumeOtherStationsByP1s) {
				if(counter < 10) {
					let stob = this.store.stations.find(item => item.id === dp.x)
					if(stob) dp.x = stob.name
					ser.data.push(dp)
				}
				counter++
			}
			return [ser]
		},
		pctListenToOtherStationsByCume() {
			let ser = {name: 'Percent of Listening', data: []}
			let counter = 0
			for(let dp of this.stationDataset.pctListenToOtherStationsByCume) {
				if(counter < 10) {
					let stob = this.store.stations.find(item => item.id === dp.x)
					if(stob) dp.x = stob.name
					ser.data.push(dp)
				}
				counter++
			}
			return [ser]
		},
		pctListenToOtherStationsByP1() {
			let ser = {name: 'Percent of Listening', data: []}
			let counter = 0
			for(let dp of this.stationDataset.pctListenToOtherStationsByP1s) {
				if(counter < 10) {
					let stob = this.store.stations.find(item => item.id === dp.x)
					if(stob) dp.x = stob.name
					ser.data.push(dp)
				}
				counter++
			}
			return [ser]
		},
		surveyOptions() {
			let survs = []
			if(this.store.returnedDataInputs && this.store.returnedDataInputs.surveys) {
				survs = this.store.surveys.filter(item => this.store.returnedDataInputs.surveys.includes(item.id))
			}
			return survs
		},
		demoOptions() {
			let demos = []
			if(this.store.returnedDataInputs && this.store.returnedDataInputs.demographics) {
				demos = this.store.demographics.filter(item => this.store.returnedDataInputs.demographics.includes(item.id))
			}
			return demos
		},
		daypartOptions() {
			let dps = []
			if(this.store.returnedDataInputs && this.store.returnedDataInputs.dayparts) {
				dps = this.store.dayparts.filter(item => this.store.returnedDataInputs.dayparts.includes(item.id))
			}
			return dps
		},
		stationOptions() {
			let st = []
			for(let s in this.myDataset) {
				st.push(this.store.stations.find(item => item.id === s))
			}
			st.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
			return st
		},
		myDataset() {
			if(this.chosenDemoId && this.chosenSurveyId && this.chosenDaypartId) {
				if(this.store.returnedData[this.chosenSurveyId]) {
					if(this.store.returnedData[this.chosenSurveyId][this.chosenDemoId]) {
						if(this.store.returnedData[this.chosenSurveyId][this.chosenDemoId][this.chosenDaypartId]) {
							return this.store.returnedData[this.chosenSurveyId][this.chosenDemoId][this.chosenDaypartId]['results']
						}
					}
				}
			}
			return null
		},
		stationDataset() {
			if(this.myDataset && this.chosenStationId && this.myDataset[this.chosenStationId]) {
				return this.myDataset[this.chosenStationId]
			}
			return null
		},
	},
	methods: {
		selectFirstIfNull() {
			if(this.store.returnedData && !this.chosenDemoId) {
				this.chosenDemoId = this.store.returnedDataInputs.demographics[0]
			}
			if(this.store.returnedData && !this.chosenSurveyId) {
				this.chosenSurveyId = this.store.returnedDataInputs.surveys[0]
			}
			if(this.store.returnedData && !this.chosenDaypartId) {
				this.chosenDaypartId = this.store.returnedDataInputs.dayparts[0]
			}
			if(this.store.returnedData && !this.chosenStationId && this.stationOptions.length > 0) {
				this.chosenStationId = this.stationOptions[0].id
			}
		},
	},
	watch: {
		'store.returnedData': {
			handler() {
				this.selectFirstIfNull()
			}
		},
	},
	mounted() {
		this.selectFirstIfNull()
	}
}
</script>

<style scoped>
.pies-holder {
	margin-top: 30px;
	display: flex;
	width: 1200px;
	gap: 2%;
}
</style>