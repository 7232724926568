<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="area"
			:options="chartoptions" :series="chartdata"></apexchart>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "RFWeeklyCume",
	components: {},
	props: {
		sourcedata: Array,
		showactualweeks: {
			type: String,
			default: 'generic',
		},
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chartoptions: {
				chart: {
					id: 'Weekly Cume Build',
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'area',
				},
				fill: {
					gradient: {
						opacityFrom: 0.4,
						opacityTo: 0.2,
					},
				},
				xaxis: {
					type: "category",
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
					labels: {
						formatter: function(value) {
							let val = Math.abs(value)
							if(val >= 1000) {
								val = (val / 1000).toFixed(0) + 'k'
							}
							return val
						}
					},
				},
				colors: ['#3669B0'],
				stroke: {
					curve: "straight",
					width: 2,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
				tooltip: {
					x: {
						// format: 'MMMM yyyy'
					}
				},
			},
		}
	},
	computed: {
		chartdata() {
			let dat = []
			for(let s of this.sourcedata) {
				let wn = 'Week '+s.weekNumber
				if(this.showactualweeks === 'actual') wn = s.actualWeekId
				else if(this.showactualweeks === 'wcdate') wn = s.actualWeekStartDate
				else if(this.showactualweeks === 'wcdatedmy') wn = this.convertYMDtoDMY(s.actualWeekStartDate)
				dat.push({x: wn, y: s.reachCumulative})
			}
			return [{
				name: 'R&F n+ reach',
				data: dat
			}]
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>