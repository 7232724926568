<template>
	<table class="excel-table-info hidden">
		<tbody>
			<tr><td></td><td></td></tr>
			<tr><td></td><td></td></tr>
		</tbody>
	</table>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "BlankExcelTwoRows",
	components: {},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>