<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="line"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import ChartLoaderInsert from "@/components/charts/ChartLoaderInsert";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ApexFreqLineTrendMulti",
	mixins: [globalMixin],
	components: {
		ChartLoaderInsert
	},
	props: {
		chartdata: Array,
		chartcolors: Array,
		fetching: Boolean,
	},
	data: function () {
		return {
			store: store,

			chartoptions: {
				chart: {
					id: "ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					}
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
					forceNiceScale: true,
				},
				// colors: [],
				stroke: {
					curve: "straight",
					width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
			},
		}
	},
	computed: {
		chartcols() {
			if(this.chartcolors && this.chartcolors.length > 0) return this.chartcolors;
			return this.store.defaultColors;
		},
	},
	methods: {
		updateColours() {
			let outcols = this.store.defaultColors;
			if(this.chartcolors && this.chartcolors.length > 0) {
				outcols = this.chartcolors;
			}
			this.chartoptions.colors = outcols;
			// this.chartoptions = {...this.chartoptions, ...{
			// 		colors: outcols
			// 	}};
		},
	},
	watch: {
		chartcolors() {
			this.updateColours();
		}
	},
	mounted() {
		this.updateColours();
	}
}
</script>

<style scoped>

</style>