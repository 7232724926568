<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata" width="99%" height="100%" type="pie"
			:options="chartoptions" :series="chartdata"></apexchart>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "RFMarketPie",
	components: {},
	props: {
		sourcedata: Array,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			chartoptions: {
				chart: {
					id: "Market Splits",
					toolbar: {
						show: true
					},
					animations: {
						enabled: true
					},
					type: 'pie',
					background: '#F9F9F9'
				},
				dataLabels: {
					enabled: true,
					distributed: true,
					formatter: function(val, opts) {
						const name = opts.w.globals.labels[opts.seriesIndex]
						return [name, val.toFixed(1) + '%']
					}
				},
				labels: this.labels,
				colors: ['#3669B0'],
			},
		}
	},
	computed: {
		chartdata() {
			let dat = []
			for(let s of this.sourcedata) {
				let val = 0
				if(s.impacts) val = parseFloat(s.impacts.replaceAll(",",""))
				dat.push(val)
			}
			return dat
		},
	},
	methods: {
		getLabels() {
			let dat = []
			for(let s of this.sourcedata) {
				dat.push(s.name)
			}
			this.chartoptions.labels = dat
			this.chartoptions.colors = this.store.defaultColors
		}
	},
	watch: {
		sourcedata() {
			this.getLabels()
		},
	},
	mounted() {
		this.getLabels()
	}
}
</script>

<style scoped>

</style>