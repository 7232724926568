<template>
	<div>
		<select @change="$emit('update', selectval)" v-model="selectval" class="selectbox">
			<option v-for="c in choices" :value="c.k" :key="c.k">{{c.v}}</option>
		</select>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "RFAudioTypeChooser",
	components: {},
	props: {
		inval: {
			type: [String, Number, Object]
		},
		station: String, //eg x_815
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			selectval: 0,
		}
	},
	computed: {
		stationObj() {
			if(!this.stationId || this.stationId.length === 0) return null
			return this.store.stations.find(item => item.id === this.stationId)
		},
		activeSurvey() {
			let mktid = null
			if(this.stationObj) mktid = this.stationObj.mktid
			if(mktid) {
				for(let survgroup of this.store.selectionObs.surveys) {
					for(let sv of survgroup.survobs) {
						if(sv.mktid === mktid) return sv
					}
				}
			}
			return null
		},
		disallowAudioType() {
			if(this.stationId) {
				if(this.stationId.indexOf('x_') === 0) return true //xtra station
				if(this.stationObj && this.activeSurvey) {
					let survid = this.activeSurvey.id
					if(this.stationObj.usage && this.stationObj.usage.surveyXUsage && this.stationObj.usage.surveyXUsage[survid]) {
						if(this.stationObj.usage.surveyXUsage[survid].streamingDataSupplied === true) return false
						else return true
					}
				}
			}
			return false
		},
		choices() {
			if(this.disallowAudioType) { //xtra
				return [{k: 0, v: 'Total Radio'}]
			}
			return [
				{k: 0, v: 'Total Radio'},
				{k: 1, v: 'AM/FM/DAB+'},
				{k: 2, v: 'Streaming'},
			]
		},
		needsChange() {
			if(this.disallowAudioType && this.selectval > 0) return true
			return false
		},
	},
	methods: {
		updateInternal() {
			this.selectval = this.inval
		}
	},
	watch: {
		inval() {
			this.updateInternal()
		},
		needsChange() {
			this.selectval = 0
		},
	},
	mounted() {
		this.updateInternal()
	}
}
</script>

<style scoped>

</style>