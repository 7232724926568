<template>
	<div class="notification-box">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "NotificationBox",
	components: {},
	props: {},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.notification-box {
	border: 1px solid var(--greymid);
	background-color: #EAEAEA;
	padding: 30px;
	border-radius: 5px;
}
</style>