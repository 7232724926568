<template>
	<div style="font-size: 11px; color: #888; cursor: pointer; margin: 15px 0;">
		<span v-if="store.showGreenHighlights" @click="clearHighlights">Clear highlights</span>
		<div v-else style="display: flex">
			<span @click="highlightByCol">Highlight by column</span>
			<span @click="highlightByRow" style="margin-left: 10px;">Highlight by row</span>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "ColourHighlightToggler",
	components: {},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>