<template>
	<div class="loader-overlay">
		<div class="chart-explorer-view">
			<div class="market-summary">
				<div class="popup-topbar">
					<span class="boldme">Audology Chart Explorer</span>
					<span class="closer" @click="store.chartExplorerOverlayShown = false">
						<i class="mdi mdi-close"></i>
					</span>
				</div>
				<div class="popup-insides">
					<div style="display: flex; justify-content: flex-start; gap: 15px; margin: 0px 0 20px;">
						<div>
							<select class="select-styled" v-model="chosenMarket">
								<option v-for="opt in availMarkets" :key="opt.id" :value="opt.id">{{opt.name}}</option>
							</select>
						</div>
						<div>
							<select class="select-styled" v-model="chosenDemographic">
								<option v-for="opt in availDemographics" :key="opt.id" :value="opt.id">{{opt.name}}</option>
							</select>
						</div>
						<div>
							<select class="select-styled" v-model="chosenDaypart">
								<option v-for="opt in availDayparts" :key="opt.id" :value="opt.id">{{opt.name}}</option>
							</select>
						</div>
						<div>
							<select class="select-styled" v-model="chosenStatistic">
								<option v-for="opt in availStatistics" :key="opt.id" :value="opt.id">{{opt.name}}</option>
							</select>
						</div>
						<div><span class="link-btn" style="padding: 13px 20px 14px; border-radius: 5px" @click="getChartData">Fetch</span></div>
						<div style="margin-top: 10px; display: flex; align-items: center; justify-content: center">
							<input id="chtrln" type="checkbox" v-model="useLineChart" />
							<label for="chtrln" style="font-size: 12px; margin: 1px 0 0 5px; display: inline-block">Trend Line chart</label>
						</div>
					</div>

					<ChartHolderDash :charttitle="chartTitle" :source="chartSource" >
						<template v-slot:default>
							<p v-if="chartdata.length === 0">
								There's either no CRA Gold Standard survey data for this market, or the demographic/daypart selection has no data available to you.
								<br/>
								This can be due to survey/market permissions (perhaps you don't have permissions to the full dataset for this market) or sample size.</p>
							<ApexFreqLineTrendMulti v-if="useLineChart && chartdata.length > 0" :chartdata="chartdata" :chartcolors="chartcolors" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
							<ApexFreqBar v-if="useLineChart === false && chartdata.length > 0" :chartdata="chartdata" :chartcolors="chartcolors" :fetching="fetchingCharts"></ApexFreqBar>
						</template>
					</ChartHolderDash>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ApexFreqBar from "@/components/charts/ApexFreqBar";
import ApexFreqLineTrendMulti from "@/components/charts/ApexFreqLineTrendMulti";
import ChartHolderDash from "@/components/charts/ChartHolderDash";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "FreqChartExplorer",
	components: {
		ApexFreqBar,
		ApexFreqLineTrendMulti,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetchingCharts: false,
			defaultColorCount: 0,
			collatedMarketData: [],
			useLineChart: false,

			chartdata: [],
			chartcolors: [],

			allowedDemoIds: [48,50,532,118,1323,152,2316,1322,266,239,3131,159,272,255,535,803,117,373,151,49,1321,265,238,3130,158,271,64,65,374,78,1326,263,66,458,3129,68,181],
			allowedDayparts: [1,3,4,5,6,7,8,9],

			chosenMarket: 0,
			chosenDemographic: 48,
			chosenStatistic: 'cume',
			chosenDaypart: 1,

			surveynames: [],
		}
	},
	computed: {
		availMarkets() {
			return this.store.markets.filter(item => item.surveyed === true && item.hidden !== 1);
		},
		availDemographics() {
			return this.store.demographics.filter(item => this.allowedDemoIds.includes(item.id));
		},
		availStatistics() {
			let myallowed = ['cume','stationListenedMost']
			if(this.store.gfkMktIds.includes(this.chosenMarket)) {
				myallowed = ['cume','shareToAll']
			}
			return this.store.statistics.filter(item => myallowed.includes(item.id));
		},
		availDayparts() {
			return this.store.dayparts.filter(item => this.allowedDayparts.includes(item.id))
		},
		appliedStations() {
			let stns = this.store.stations.filter(item => item.mktid === this.chosenMarket && item.band !== 'dab' && item.outsidemkt !== true && item.community === false && ((item.isCommercial === true && item.band.length > 0) || item.subnetown === 'abc_loc' || item.subnetown === 'abc_j'));
			let stationids = [];
			for(let s=0; s<stns.length; s++) {
				stationids.push(stns[s].id);
			}
			return stationids;
		},
		chartTitle() {
			let dem = this.store.demographics.find(item => item.id === this.chosenDemographic);
			let mkt = this.store.markets.find(item => item.id === this.chosenMarket);
			let dp = this.store.dayparts.find(item => item.id === this.chosenDaypart);
			let stt = this.store.statistics.find(item => item.id === this.chosenStatistic);
			if(dem && mkt && stt) {
				return mkt.name + ", " + dem.name + ", " + dp.name + ", " + stt.name;
			}
			return '';
		},
		chartSource() {
			let dem = this.store.demographics.find(item => item.id === this.chosenDemographic);
			let mkt = this.store.markets.find(item => item.id === this.chosenMarket);
			let dp = this.store.dayparts.find(item => item.id === this.chosenDaypart);
			let stt = this.store.statistics.find(item => item.id === this.chosenStatistic);
			if(dem && mkt && stt) {
				let researchco = "Xtra Insights"
				if(mkt.isGfk === true) researchco = "GFK"
				return researchco + " " + mkt.name + " surveys (" + this.surveynames.join(", ") + "), " + dem.name + ", " + dp.name + ", " + stt.name;
			}
			return '';
		},
		activeMarketOb() {
			return this.store.markets.find(item => item.id === this.chosenMarket)
		},
		surveyList() {
			if(this.activeMarketOb) {
				return this.store.surveys.filter(item => item.mktid === this.activeMarketOb.id)
			}
			return []
		},
		surveyListIds() {
			let ss = []
			for(let su of this.surveyList) {
				ss.push(su.id)
			}
			return ss
		},
	},
	methods: {
		getChartData() { //for cache lookup
			if(this.fetchingCharts === false) {
				this.fetchingCharts = true
				let self = this
				let sets = []
				let type = 'standard'
				if(this.chosenMarket === 900) type = 'smbap'
				sets.push({
					type: type,
					dayparts: [this.chosenDaypart],
					demos: [this.chosenDemographic],
					statistics: [this.chosenStatistic],
					surveylist: this.surveyListIds,
					stationIds: this.appliedStations,
					market: this.chosenMarket,
					audioTypeIds: [0],
				})
				this.doGetCacheDataAudology(sets).then(function(ret) {
					if(ret.error === 1) console.log(ret)
					else {
						self.collatedMarketData = ret.returnedData
						self.prepareChartData()
						self.fetchingCharts = false
					}
				})
			}
		},
		prepareChartData() {
			this.chartdata = []
			let cdata = [];
			let colors = [];
			this.defaultColorCount = 0
			this.surveynames = []
			if (this.chosenDemographic > 0 && this.chosenStatistic.length > 0 && this.chosenDaypart > 0) {
				this.store.defaultColorCount = 0
				for (let s = 0; s < this.appliedStations.length; s++) {
					let stid = this.appliedStations[s]
					let stnob = this.store.stations.find(item => item.id === stid);
					if(stnob) {
						let myrow = []
						myrow = {
							name: stnob.name,
							data: [],
						}
						colors.push(this.getChartColorForStation(stnob, colors));
						for(let surv of this.surveyList) {
							myrow.data.push({x: surv.nicename, y: this.getCellDataAud(stnob.id, surv.id, this.collatedMarketData, this.chosenStatistic, this.chosenDaypart, this.chosenDemographic, 0)})
						}
						myrow.data.reverse()
						cdata.push(myrow)
					}
				}
				this.chartdata = cdata
				this.chartcolors = colors
			}
		},
	},
	watch: {
		chosenMarket() {
			if(this.store.gfkMktIds.includes(this.chosenMarket)) {
				this.useLineChart = true
				if(this.chosenStatistic === 'stationListenedMost') this.chosenStatistic = 'shareToAll'
			}
			else {
				if(this.chosenStatistic === 'shareToAll') this.chosenStatistic = 'stationListenedMost'
			}
			this.getChartData();
		},
		chosenStatistic() { this.getChartData(); },
		chosenDemographic() { this.getChartData(); },
		chosenDaypart() { this.getChartData(); },
	},
	mounted() {
		if(this.chosenMarket === 0) {
			this.chosenMarket = this.availMarkets[0].id;
		}
		if(this.store.gfkMktIds.includes(this.chosenMarket)) this.useLineChart = true
		if(this.chosenMarket > 0 && this.appliedStations.length > 0 && this.chosenStatistic > 0 && this.chosenDemographic > 0 && this.chosenDaypart > 0) {
			this.getChartData();
		}
	}
}
</script>

<style scoped>
.chart-explorer-view {
	width: 80%;
	height: 800px;
	max-height: 90%;
	position: fixed;
	top: 5%;
	left: 10%;
}
.market-summary {
	display: flex;
	background-color: #FFF;
	overflow-y: auto;
	height: 100%;
}
.content-block p {
	line-height: 1.6;
}
.select-styled {
	padding: 9px 7px;
	border: 1px solid #BBB;
	border-radius: 5px;
	-webkit-appearance: auto;
	-moz-appearance: auto;
	font-size: 16px;
	width: 100%;
	display: inline-block;
}
@media screen and (max-width: 1350px) {
	.chart-explorer-view {
		width: 90%;
		left: 5%;
	}
}
</style>