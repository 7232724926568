<template>
	<div id="excel-basic-wrapper">
		<table class="excel-table-info" :class="{shown : isExporting}">
			<tbody>
			<tr>
				<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">Demographic</td>
				<td data-f-name="Tahoma" data-f-sz="10">{{demodata.demoName}}</td>
			</tr>
			<tr>
				<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">Markets</td>
				<td data-f-name="Tahoma" data-f-sz="10">{{marketListText}}</td>
			</tr>
			</tbody>
		</table>
		<BlankExcelTwoRows></BlankExcelTwoRows>
		<div class="table-holder">
			<table v-if="demodata && demodata.summary" class="std-tbl">
				<thead>
				<tr class="thead">
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Market</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Spot Count</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Sample Size</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Universe Est</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">1+</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Frequency</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">1+ %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">3+</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">3+ %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Gross Impacts</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Aud</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
				</tr>
				</thead>
				<tbody>
				<tr v-for="mk in demodata.marketSplits" :key="mk.id">
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.marketName}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.nSpots}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.sampleSize)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.universeEstimate)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(getItemProperty(mk.nPlusExposure.find(item => item.spot === 1), 'nPlusReach'))}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.avgFrequency}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(getItemProperty(mk.nPlusExposure.find(item => item.spot === 1), 'nPlusPct'), 2)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(getItemProperty(mk.nPlusExposure.find(item => item.spot === 3), 'nPlusReach'))}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(getItemProperty(mk.nPlusExposure.find(item => item.spot === 3), 'nPlusPct'), 2)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.impacts)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.avgAud)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.cost)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(mk.cpm, 2)}}</td>
				</tr>

				<tr class="thead">
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Network</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Spot Count</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Sample Size</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Universe Est</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">1+</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Frequency</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">1+ %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">3+</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">3+ %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Gross Impacts</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Aud</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
				</tr>
				<tr v-for="co in comboRows" :key="co.id">
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{co.name}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{co.nSpots}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">-</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(co.universeEstimate)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(getItemProperty(co.nPlusExposure.find(item => item.spot === 1), 'nPlusReach'))}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{co.avgFrequency}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(getItemProperty(co.nPlusExposure.find(item => item.spot === 1), 'nPlusPct'), 2)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(getItemProperty(co.nPlusExposure.find(item => item.spot === 3), 'nPlusReach'))}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(getItemProperty(co.nPlusExposure.find(item => item.spot === 3), 'nPlusPct'), 2)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(co.impacts)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(co.avgAud)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(co.cost)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(co.cpm, 2)}}</td>
				</tr>

				<tr class="thead">
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Station</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Spot Count</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Sample Size</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Universe Est</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">1+</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Frequency</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">1+ %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">3+</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">3+ %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Gross Impacts</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Aud</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
				</tr>
				<tr v-for="mk in demodata.stationResults" :key="mk.id">
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{getStationNameFromId(mk.stationId, true)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.nSpots}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.sampleSize)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.universeEstimate)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(getItemProperty(mk.nPlusExposure.find(item => item.spot === 1), 'nPlusReach'))}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.avgFrequency}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(getItemProperty(mk.nPlusExposure.find(item => item.spot === 1), 'nPlusPct'), 2)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(getItemProperty(mk.nPlusExposure.find(item => item.spot === 3), 'nPlusReach'))}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(getItemProperty(mk.nPlusExposure.find(item => item.spot === 3), 'nPlusPct'), 2)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.impacts)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.avgAud)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.cost)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(mk.cpm, 2)}}</td>
				</tr>

				<tr class="thead">
					<td colspan="13"></td>
				</tr>
				<tr class="thead">
					<td colspan="13" style="font-weight: normal;" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">
						* Please note that the sum of individual week splits may show slightly different results to the overall total due to rounding.
					</td>
				</tr>

				<tr class="thead">
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Week (Cume)</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Spot Count</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Sample Size</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Universe Est</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">1+</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Frequency</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">1+ %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">3+</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">3+ %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Gross Impacts</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Aud</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
				</tr>
				<tr v-for="mk in demodata.weekSplits" :key="mk.weekNumber">
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{getDisplayWeek(mk, 'cumulative')}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.nSpotsCumulative}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.sampleSize)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.universeEstimate)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(getItemProperty(mk.nPlusExposure.find(item => item.spot === 1), 'nPlusReach'))}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.avgFrequencyCumulative}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(getItemProperty(mk.nPlusExposure.find(item => item.spot === 1), 'nPlusPct'), 2)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(getItemProperty(mk.nPlusExposure.find(item => item.spot === 3), 'nPlusReach'))}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(getItemProperty(mk.nPlusExposure.find(item => item.spot === 3), 'nPlusPct'), 2)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.impactsCumulative)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.avgAudCumulative)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.costCumulative)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(mk.cpmCumulative, 2)}}</td>
				</tr>

				<tr class="thead">
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Week</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Spot Count</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Sample Size</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Universe Est</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">1+</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Frequency</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">1+ %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">3+</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">3+ %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Gross Impacts</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Aud</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
				</tr>
				<tr v-for="mk in demodata.weekSplits" :key="mk.weekNumber">
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{getDisplayWeek(mk)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.nSpots}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.sampleSize)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.universeEstimate)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.reach)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.avgFrequency}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(mk.reachPct, 2)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(getItemProperty(mk.nPlusExposureThisWeek.find(item => item.spot === 3), 'nPlusReach'))}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(getItemProperty(mk.nPlusExposureThisWeek.find(item => item.spot === 3), 'nPlusPct'), 2)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.impacts)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.avgAud)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.cost)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(mk.cpm, 2)}}</td>
				</tr>

				</tbody>
			</table>
			<BlankExcelTwoRows></BlankExcelTwoRows>
			<table class="excel-table-info" :class="{shown : isExporting}">
				<tbody>
				<tr>
					<td data-f-name="Tahoma" data-f-sz="10">Source: {{store.returnedDataSourcing}}</td>
				</tr>
				</tbody>
			</table>
		</div>

		<div class="primary-button" @click="exportToExcel()" style="margin-top: 20px;">
			<i class="mdi mdi-file-excel-outline"></i>
			Export To Excel
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlankExcelTwoRows from "@/components/result-tables/BlankExcelTwoRows.vue";
import TableToExcel from "@linways/table-to-excel";

export default {
	name: "RFResultSummaryTable",
	components: {
		BlankExcelTwoRows
	},
	props: {
		demodata: {
			type: Object,
		},
		showactualweeks: {
			type: String,
			default: 'generic'
		},
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			isExporting: false,

		}
	},
	computed: {
		marketListText() {
			let ms = []
			for(let mk of this.demodata.marketSplits) {
				ms.push(mk.marketName)
			}
			return ms.join(', ')
		},
		firstWeekDisplayName() {
			if(this.showactualweeks === 'actual') return this.demodata.weekSplits[0].actualWeekId
			else if(this.showactualweeks === 'wcdate') return this.demodata.weekSplits[0].actualWeekStartDate
			else if(this.showactualweeks === 'wcdatedmy') return this.convertYMDtoDMY(this.demodata.weekSplits[0].actualWeekStartDate)
			return 'W'+this.demodata.weekSplits[0].weekNumber
		},
		comboRows() {
			let rowdata = []
			for(let cid in this.demodata.combos) {
				let rt = this.demodata.combos[cid]
				let r = rt.summary
				r.id = cid
				let cob = this.store.stationcombos.find(c => c.id === r.id)
				if(cob) {
					r.name = cob.name
					rowdata.push(r)
				}
			}
			return rowdata
		},
	},
	methods: {
		getDisplayWeek(mk, type = 'singleweek') {
			let out = ''
			if(type === 'cumulative') out = this.firstWeekDisplayName + ' to '
			if (this.showactualweeks === 'actual') return out + mk.actualWeekId
			else if (this.showactualweeks === 'wcdate') {
				if(type === 'cumulative') return out + mk.actualWeekEndDate
				else return mk.actualWeekStartDate
			}
			else if (this.showactualweeks === 'wcdatedmy') {
				if(type === 'cumulative') return out + this.convertYMDtoDMY(mk.actualWeekEndDate)
				else return this.convertYMDtoDMY(mk.actualWeekStartDate)
			}
			return out + 'W' + mk.weekNumber
		},
		getItemProperty(item, propname) {
			if(!item) return null
			return item[propname]
		},
		exportToExcel() {
			this.excelbook = null;
			this.isExporting = true
			let content = document.querySelector("#excel-basic-wrapper")
			let contentT = content.cloneNode(true)
			contentT.innerHTML = contentT.innerHTML.replace(/,/g,''); //replaces , globally
			TableToExcel.convert(contentT, {
				name: "Audology RF Summary.xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
			let self = this
			setTimeout(function() {
				self.isExporting = false
			}, 1000)
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.excel-table-info {
	display: none;
}
.excel-table-info.shown {
	display: table;
}
.table-holder {
	width: 100%;
	overflow-x: auto;
}
</style>