<template>
	<div @click="$emit('set', id)"
		class="interiorTab"
		:class="{activet : isactive}"
	>
		{{name}}
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "InteriorTab",
	components: {},
	props: {
		id: String,
		name: String,
		isactive: Boolean,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.interiorTab {
	border: 1px solid transparent;
	border-bottom: 1px solid #DDD;
	width: 100%;
	padding: 10px 0;
	text-align: center;
	cursor: pointer;
	font-size: 12px;
	font-weight: bold;
	/*border-radius: 10px 10px 0 0;*/
	transition: all 0.3s ease-in-out;
}
.interiorTab.activet {
	background-color: var(--greylight);
	border: 1px solid #DDD;
	border-bottom: 1px solid var(--greylight);
}
.interiorTab:not(.activet):hover {
	background: var(--greylight);
}
</style>