<template>
	<div>
		<div v-if="useselecteddayparts === true">
			<select @change="$emit('update', selectval)" v-model="selectval" class="selectbox">
				<option v-for="c in choices" :value="c.id" :key="c.id">{{ucfirst(c.name)}}</option>
			</select>
		</div>
		<div v-else>
			<select @change="$emit('update', selectval)" v-model="selectval" class="selectbox">
				<option v-for="c in choices" :value="c" :key="c">{{ucfirst(c)}}</option>
			</select>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "RFDaypartChooser",
	components: {},
	props: {
		inval: {
			type: [String, Number]
		},
		useselecteddayparts: {
			type: Boolean,
			default: false
		},
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			selectval: null,
		}
	},
	computed: {
		choices() {
			if(this.useselecteddayparts === true) {
				return this.store.selectionObs.dayparts
			}
			return ['Breakfast','Morning','Afternoon','Drive','Night','Weekend']
		},
	},
	methods: {
		updateInternal() {
			this.selectval = this.inval
		}
	},
	watch: {
		inval() {
			this.updateInternal()
		}
	},
	mounted() {
		this.updateInternal()
	}
}
</script>

<style scoped>

</style>