<template>
	<div class="market-summary">
		<div class="popup-topbar">
			<span class="boldme">{{mktob.name}}, {{mktob.state}}</span>
			<span class="closer" @click="$emit('close')">
				<i class="mdi mdi-close"></i>
			</span>
		</div>
		<div class="popup-insides">
			<br/>
			<div class="top-content">
				<div class="half content-block">
					<p class="heading">{{mktob.name}}, {{mktob.state}}</p>
					<p class="api-info">API ID: {{mktob.id}}</p>

					<p v-if="mktob.surveyed === true && !store.gfkMktIds.includes(mktob.id) && latestSurv">
						<span class="bold">Latest Survey: </span>{{latestSurv.name}} ({{latestSurv.reldate}})
					</p>
					<p v-else-if="store.gfkMktIds.includes(mktob.id)"><span class="bold">This market is surveyed by GFK</span></p>
					<p v-else class="bold">Not surveyed</p>

					<p><span class="bold">10+ Population: </span>{{numdisplay(mktob.indpop)}}</p>
					<p><span class="bold">TV Agg Mkt: </span>{{getTVAggMktName(mktob.tvAggId)}}</p>
					<p><a target="_blank" :href="mktob.craPDFurl">CRA Market Summary</a></p>

					<br/>
					<p class="boldme">Operators</p>
					<div>
						<OwnershipLogo v-for="o in mktob.ownership.owns" :key="o" type="own" :str="o" wid="100px" ht="auto"></OwnershipLogo>
					</div>

					<br/>
					<div v-if="mktob.isGfk === false">
						<p class="boldme">Commercial Stations</p>
						<div class="station-cards" style="margin-top: 10px; font-size: 15px;">
							<div v-for="stn in comStations" :key="stn.id" class="station-card">
								<div style="height: 64px;">
									<p class="bold">{{stn.name}} <span v-if="stn.band.length > 0">({{stn.band.toUpperCase()}})</span></p>
								</div>
								<div class="stnlogohold" style="height: 120px; border-bottom: 1px solid #DDD;">
									<StationLogo :stnob="stn"></StationLogo>
								</div>
								<div style="padding: 10px 0; border-bottom: 1px solid #DDD;">
									<OwnershipLogo type="own" :str="stn.netgroupown" wid="80px" ht="auto"></OwnershipLogo>
								</div>
								<div style="padding: 10px 0 0; display: flex; align-items: center; justify-content: center;">
									<OwnershipLogo v-if="stn.repnetgroup.length > 0" type="reps" :str="stn.repnetgroup"></OwnershipLogo>
								</div>
							</div>
						</div>
					</div>

				</div>

				<div class="half content-block map-holder" style="">
					<img :src="mapUrl" alt="Market map" style="max-width: 500px; object-fit: contain;" />
				</div>
			</div>

			<div v-if="mktob.surveyed === true && !store.gfkMktIds.includes(mktob.id) && latestSurv" style="margin-top: 40px;">
				<p style="font-size: 12px;">Charts shown below utilise publicly released/available data.
					<span v-if="userCanViewFull">To view custom demographics and dayparts, use a Audology Standard Report.</span>
					<span v-else>Your account/network does not have access to full survey data for this market.</span>
				</p>
				<div style="margin-top: 10px; display: flex; align-items: center; justify-content: center">
					<input id="chtrln" type="checkbox" v-model="useLineChart" />
					<label for="chtrln" style="font-size: 12px; margin: 1px 0 0 5px; display: inline-block">Trend Line chart</label>
				</div>
			</div>

			<div v-if="mktob.surveyed === true" class="chart-rows">
				<ChartHolderDash :charttitle="charttitle10sltm" :source="chartsource10sltm" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata10sltm" :chartcolors="chartcolors10sltm" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata10sltm" :chartcolors="chartcolors10sltm" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>

				<ChartHolderDash :charttitle="charttitle10cume" :source="chartsource10cume" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata10cume" :chartcolors="chartcolors10cume" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata10cume" :chartcolors="chartcolors10cume" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div v-if="mktob.surveyed === true" class="chart-rows">
				<ChartHolderDash :charttitle="charttitle10bfmost" :source="chartsource10bfmost" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata10bfmost" :chartcolors="chartcolors10bfmost" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata10bfmost" :chartcolors="chartcolors10bfmost" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>

				<ChartHolderDash :charttitle="charttitle10dvmost" :source="chartsource10dvmost" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata10dvmost" :chartcolors="chartcolors10dvmost" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata10dvmost" :chartcolors="chartcolors10dvmost" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div v-if="mktob.surveyed === true" class="chart-rows">
				<ChartHolderDash :charttitle="charttitle2554sltm" :source="chartsource2554sltm" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata2554sltm" :chartcolors="chartcolors2554sltm" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata2554sltm" :chartcolors="chartcolors2554sltm" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>

				<ChartHolderDash :charttitle="charttitle2554cume" :source="chartsource2554cume" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata2554cume" :chartcolors="chartcolors2554cume" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata2554cume" :chartcolors="chartcolors2554cume" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div v-if="mktob.surveyed === true" class="chart-rows">
				<ChartHolderDash charttitle="ROS Average Age" source="10+, Average Age, Mon-Sun All Times" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqBar :chartdata="chartdata10age" :chartcolors="chartcolors10age" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>

				<ChartHolderDash v-if="isGFK === false" charttitle="10+ Exclusive Cume 00s" source="Xtra Insights: 10+, Exclusive Cume 00s, Mon-Sun All Times" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqBar :chartdata="chartdata10excl" :chartcolors="chartcolors10excl" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>

				<ChartHolderDash v-if="isGFK === true" charttitle="10+ TSL (hours)" source="GFK: 10+ TSL per week" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata10tsl" :chartcolors="chartcolors10tsl" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata10tsl" :chartcolors="chartcolors10tsl" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div v-if="mktob.surveyed === true" class="chart-rows">
				<ChartHolderDash :charttitle="charttitle10mfsplit" :source="chartsource10mfsplit" :height="360" addclasses="half">
					<template v-slot:default>
						<PctCumeFemale :source="dataSource" :mktob="mktob" :indata="collatedMarketData" :fetchingcharts="fetchingCharts" :stnids="stationIdsToUse"></PctCumeFemale>
					</template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Age & Gender Crop Circles" :source="chartsourceCropCircle" :height="360" addclasses="half">
					<template v-slot:default>
						<CropCircles :source="dataSource" :mktob="mktob" :indata="collatedMarketData" :fetchingcharts="fetchingCharts" :stnids="stationIdsToUse"></CropCircles>
					</template>
				</ChartHolderDash>
			</div>

		</div>
	</div>
</template>

<script>
import StationLogo from "@/components/StationLogo";
import OwnershipLogo from "@/components/OwnershipLogo";
import ChartHolderDash from "@/components/charts/ChartHolderDash";
import ApexFreqLineTrendMulti from "@/components/charts/ApexFreqLineTrendMulti";
import ApexFreqBar from "@/components/charts/ApexFreqBar";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import PctCumeFemale from "@/components/charts/PctCumeFemale.vue";
import CropCircles from "@/components/charts/CropCircles.vue";
export default {
	name: "MarketSummary",
	components: {
		CropCircles,
		PctCumeFemale,
		ApexFreqBar,
		ApexFreqLineTrendMulti,
		ChartHolderDash,
		OwnershipLogo,
		StationLogo,
	},
	mixins: [globalMixin],
	props: {
		mktob: Object,
	},
	data: function () {
		return {
			store: store,

			gfkmktids: [14,19,72,110,111,112,113,114,999,1000,1001,1002,1003],
			statisticIds: ['stationListenedMost','shareToAll','cume','avgAge','exclusiveCume','tslHrsDecimal'],
			fetchingCharts: true,
			defaultColorCount: 0,
			collatedMarketData: [],

			chartdata10sltm: [],
			chartcolors10sltm: [],

			chartdata10cume: [],
			chartcolors10cume: [],

			chartdata10bfmost: [],
			chartcolors10bfmost: [],

			chartdata10dvmost: [],
			chartcolors10dvmost: [],

			chartdata2554sltm: [],
			chartcolors2554sltm: [],

			chartdata2554cume: [],
			chartcolors2554cume: [],

			chartdata10age: [],
			chartcolors10age: [],

			chartdata10excl: [],
			chartcolors10excl: [],

			chartdata10tsl: [],
			chartcolors10tsl: [],

			chartdatafemalecumedatagfk: [],
			chartdatamalecumedatagfk: [],

			useLineChart: false,
		}
	},
	computed: {
		isGFK() {
			if(this.store.gfkMktIds.includes(this.mktob.id)) {
				return true;
			}
			return false;
		},
		charttitle10sltm() {
			if(this.isGFK) return '10+ Share';
			return '10+ Station Listened To Most';
		},
		chartsource10sltm() {
			if(this.isGFK) return 'GFK: 10+ All People, Share, Mon-Sun 5.30am-12mn';
			return 'Xtra Insights: 10+ All People, Station Listened To Most, Mon-Sun All Times';
		},
		charttitle10cume() {
			if(this.isGFK) return '10+ Cume 000s';
			return '10+ Cume 00s';
		},
		chartsource10cume() {
			if(this.isGFK) return 'GFK: 10+ All People, Cume 000s, Mon-Sun 5.30am-12mn';
			return 'Xtra Insights: 10+ All People, Cume 00s, Mon-Sun All Times';
		},
		charttitle10bfmost() {
			if(this.isGFK) return '10+ Breakfast Share';
			return '10+ Breakfast Station Listened To Most';
		},
		chartsource10bfmost() {
			if(this.isGFK) return 'GFK: 10+ All People, Breakfast Share, Mon-Fri 5.30am-9am';
			return 'Xtra Insights: 10+ All People, Station Listened To Most, Mon-Fri 0530-0900';
		},
		charttitle10dvmost() {
			if(this.isGFK) return '10+ Drive Share';
			return '10+ Drive Station Listened To Most';
		},
		chartsource10dvmost() {
			if(this.isGFK) return 'GFK: 10+ All People, Drive Share, Mon-Fri 4pm-7pm';
			return 'Xtra Insights: 10+ All People, Station Listened To Most, Mon-Fri 1600-1900';
		},
		charttitle2554sltm() {
			if(this.isGFK) return '25-54 Share';
			return '25-54 Station Listened To Most';
		},
		chartsource2554sltm() {
			if(this.isGFK) return 'GFK: 25-54+ All People, Share, Mon-Sun 5.30am-12mn';
			return 'Xtra Insights: 25-54, Station Listened To Most, Mon-Sun All Times';
		},
		charttitle2554cume() {
			if(this.isGFK) return '25-54 Cume 000s';
			return '25-54 Cume 00s';
		},
		chartsource2554cume() {
			if(this.isGFK) return 'GFK: 25-54+ All People, Cume 000s, Mon-Sun 5.30am-12mn';
			return 'Xtra Insights: 25-54, Cume 00s, Mon-Sun All Times';
		},
		charttitle10mfsplit() {
			if(this.isGFK) return '10+ Cume 000s Gender Split';
			return '10+ Cume 00s Gender Split';
		},
		chartsource10mfsplit() {
			if(this.isGFK) return 'GFK: 10+, Cume 000s, Mon-Sun All Times';
			return 'Xtra Insights: 10+, Cume 00s, Mon-Sun All Times';
		},
		chartsourceCropCircle() {
			if(this.isGFK) return 'GFK: 10+, Cume by Gender & Average Age, Mon-Sun All Times';
			return 'Xtra Insights: 10+, Cume by Gender & Average Age, Mon-Sun All Times';
		},
		dataSource() {
			if(this.isGFK === true) return 'gfk';
			return 'xtra';
		},
		mapUrl() {
			return this.store.logosRoot + "mkt-maps/" + this.mktob.id + ".jpg";
		},
		comStations() {
			return this.store.stations.filter(item => item.mktid === this.mktob.id && item.isCommercial === true && item.community === false && item.outsidemkt === false && item.band !== 'dab' && item.band.length > 0);
		},
		surveys() {
			let s = this.store.surveys.filter(item => item.mktid === this.mktob.id);
			s = s.sort((a, b) => parseInt(a.reldateUnix) - parseInt(b.reldateUnix)); //sort oldest to newest
			return s;
		},
		latestSurv() {
			let latestSurv = this.surveys[this.surveys.length-1];
			if(latestSurv) return latestSurv;
			return false;
		},
		userCanViewFull() {
			if(this.latestSurv && this.latestSurv.userCanViewFull === true) {
				return true;
			}
			return false;
		},
		stationIdsToUse() {
			let stns = this.store.stations.filter(item => item.mktid === this.mktob.id && (item.band === 'fm' || item.band === 'am') && item.outsidemkt !== true && item.community === false && (item.isCommercial === true || item.subnetown === 'abc_loc' || item.subnetown === 'abc_j'));
			let stationids = [];
			for(let s=0; s<stns.length; s++) {
				if(stns[s].isActive && stns[s].isActive === true || stns[s].isActive === null) {
					stationids.push(stns[s].id)
				}
			}
			return stationids;
		},
		surveyList() {
			if(this.mktob) {
				return this.store.surveys.filter(item => item.mktid === this.mktob.id).slice(0,9);
			}
			return []
		},
		surveyListIds() {
			let ss = []
			for(let su of this.surveyList) {
				ss.push(su.id)
			}
			return ss
		},
	},
	methods: {
		getChartData() { //for cache lookup
			this.fetchingCharts = true;
			let self = this
			let sets = []
			sets.push({
				type: 'standard',
				dayparts: [1,3,6],
				demos: [48,78,49,50], //10+, 25-54, M10+, F10+
				statistics: self.statisticIds,
				surveylist: this.surveyListIds,
				stationIds: this.stationIdsToUse,
				market: this.mktob.id,
				audioTypeIds: [0],
			})
			this.doGetCacheDataAudology(sets).then(function(ret) {
				if(ret.error === 1) console.log(ret)
				else {
					self.collatedMarketData = ret.returnedData
					self.prepareChartData('sltm10')
					self.prepareChartData('cume10')
					self.prepareChartData('bfmost10')
					self.prepareChartData('dvmost10')
					self.prepareChartData('sltm2554')
					self.prepareChartData('cume2554')
					self.prepareChartData('age10')
					self.prepareChartData('excl10')
					self.prepareChartData('tsl10')
					self.fetchingCharts = false
				}
			})
		},
		prepareChartData(name) {
			let cdata = [];
			let colors = [];
			this.defaultColorCount = 0;
			let statid = undefined;
			let demoid = undefined;
			let daypid = undefined;
			if(name === 'sltm10') {
				if(this.isGFK) statid = 'shareToAll'
				else statid = 'stationListenedMost'
				demoid = 48;
				daypid = 1
			}
			else if(name === 'cume10') {
				statid = 'cume';
				demoid = 48;
				daypid = 1
			}
			else if(name === 'bfmost10') {
				if(this.isGFK) statid = 'shareToAll'
				else statid = 'stationListenedMost'
				demoid = 48;
				daypid = 3
			}
			else if(name === 'dvmost10') {
				if(this.isGFK) statid = 'shareToAll'
				else statid = 'stationListenedMost'
				demoid = 48;
				daypid = 6
			}
			else if(name === 'sltm2554') {
				if(this.isGFK) statid = 'shareToAll'
				else statid = 'stationListenedMost'
				demoid = 78;
				daypid = 1
			}
			else if(name === 'cume2554') {
				statid = 'cume';
				demoid = 78;
				daypid = 1
			}
			else if(name === 'age10') {
				statid = 'avgAge';
				demoid = 48;
				daypid = 1
			}
			else if(name === 'excl10') {
				statid = 'exclusiveCume'
				demoid = 48;
				daypid = 1
			}
			else if(name === 'tsl10') {
				statid = 'tslHrsDecimal'
				demoid = 48;
				daypid = 1
			}
			if(demoid !== undefined && statid !== undefined) {
				this.store.defaultColorCount = 0
				for (let s = 0; s < this.stationIdsToUse.length; s++) {
					let stid = this.stationIdsToUse[s];
					let stnob = this.store.stations.find(item => item.id === stid);
					if(stnob) {
						let myrow = []
						myrow = {
							name: stnob.name,
							data: [],
						}
						colors.push(this.getChartColorForStation(stnob, colors));
						for(let surv of this.surveyList) {
							myrow.data.push({x: surv.nicename, y: this.getCellDataAud(stnob.id, surv.id, this.collatedMarketData, statid, daypid, demoid, 0)})
						}
						myrow.data.reverse()
						cdata.push(myrow)
					}
				}
				if(name === 'sltm10') {
					this.chartdata10sltm = cdata;
					this.chartcolors10sltm = colors;
				}
				else if(name === 'cume10') {
					this.chartdata10cume = cdata;
					this.chartcolors10cume = colors;
				}
				else if(name === 'bfmost10') {
					this.chartdata10bfmost = cdata;
					this.chartcolors10bfmost = colors;
				}
				else if(name === 'dvmost10') {
					this.chartdata10dvmost = cdata;
					this.chartcolors10dvmost = colors;
				}
				else if(name === 'sltm2554') {
					this.chartdata2554sltm = cdata;
					this.chartcolors2554sltm = colors;
				}
				else if(name === 'cume2554') {
					this.chartdata2554cume = cdata;
					this.chartcolors2554cume = colors;
				}
				else if(name === 'age10') {
					let latestarr = this.getLatestOnly(cdata, colors)
					this.chartdata10age = latestarr[0]
					this.chartcolors10age = latestarr[1]
				}
				else if(name === 'excl10') {
					let latestarr = this.getLatestOnly(cdata, colors)
					this.chartdata10excl = latestarr[0]
					this.chartcolors10excl = latestarr[1]
				}
				else if(name === 'tsl10') {
					this.chartdata10tsl = cdata;
					this.chartcolors10tsl = colors;
				}
			}
		},
		getLatestOnly(cdata, incolors) {
			let c = [];
			for(let i=0; i<cdata.length; i++) { //each of these is a station
				let datalength = cdata[i].data.length;
				let a = {
					name: cdata[i].name,
					data: [{x: 'Survey', y: 0}]
				}
				let val = cdata[i].data[datalength-1]
				if(val) a.data = [cdata[i].data[datalength-1]]
				c.push(a)
			}
			return [c, incolors];
		},
	},
	watch: {},
	mounted() {
		if(this.isGFK) this.useLineChart = true
		setTimeout(function() {
			this.getChartData();
		}.bind(this), 150);
	}
}
</script>

<style scoped>
.market-summary {
	display: flex;
	background-color: #FFF;
	overflow-y: auto;
	height: 100%;
}
.top-content {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}
.station-cards {
	display: flex;
	gap: 20px;
	text-align: center;
	flex-wrap: wrap;
}
.station-card {
	background-color: var(--greylight);
	border-radius: 10px;
	padding: 20px;
	width: 160px
}
.stnlogohold {
	width: 120px;
	height: 120px;
	padding: 10px;
}
.chart-rows {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}
.half {
	width: 49%;
	min-width: 49%;
}
.content-block {
	text-align: left;
}
.map-holder {
	text-align: right;
}
.content-block p {
	line-height: 1.6;
}

@media screen and (max-width: 1199px) {
	.top-content {
		display: block;
	}
	.top-content .half {
		width: 100%;
		min-width: 100%;
	}
	.map-holder {
		margin-top: 30px;
		text-align: left;
	}
	.chart-rows .half {
		width: 100%;
		min-width: 100%;
	}
}
</style>