<template>
	<div>
		<WarningsBox v-if="demodata && demodata.warnings && demodata.warnings.length > 0" :warnings="demodata.warnings"></WarningsBox>
		<div v-if="dataorspotsview === 'data' || exportingtoexcel === true" class="topsection">
			<div class="overview-table">
				<table class="excel-table-info">
					<tbody>
					<tr>
						<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">Summary for market: {{chosenMarketData.marketName}}</td>
					</tr>
					<tr><td></td><td></td></tr>
					<tr v-if="exportingtoexcel"><td>Demographic: </td><td>{{demodata.demoName}}</td></tr>
					<tr v-if="exportingtoexcel"><td>Radio Type: </td><td>{{getAudioTypeNameFromId(demodata.audioType)}}</td></tr>
					<tr v-if="exportingtoexcel"><td></td><td></td></tr>
					</tbody>
				</table>
				<RFSummaryTable v-if="chosenMarketData" :summarydata="chosenMarketData"></RFSummaryTable>
			</div>
			<div class="nplusSection">
				<div class="nplusTable">
					<RFNPlusExposureTable v-if="chosenMarketData.nPlusExposure" :tdata="chosenMarketData.nPlusExposure" :ue="chosenMarketData.universeEstimate"></RFNPlusExposureTable>
				</div>
				<div class="nplusChartHold">
					<p class="minor-heading">N+ Reach</p>
					<RFNPlusChart v-if="chosenMarketData && chosenMarketData.nPlusExposure" :sourcedata="chosenMarketData.nPlusExposure"></RFNPlusChart>
				</div>
			</div>
		</div>

		<div v-if="dataorspotsview === 'data' || exportingtoexcel === true" class="halvedsections">
			<div>
				<table class="excel-table-info">
					<tbody>
					<tr>
						<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">Splits by {{chosenMarketData.marketName}} stations</td>
					</tr>
					<tr><td></td><td></td></tr>
					</tbody>
				</table>
				<div v-if="chosenMarketData && stationResultsForMarket && stationResultsForMarket.length > 0" class="rfMarketPieHold">
					<RFStationPie :sourcedata="stationResultsForMarket"></RFStationPie>
				</div>
				<div class="table-holder">
					<table v-if="chosenMarketData && stationSummaryData && stationSummaryData.length > 0" class="std-tbl" style="margin-top: 30px; margin-bottom: 30px;">
						<thead>
						<tr>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Station</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">N Spots</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Impacts</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">GRPs</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cume</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cume %</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Frequency</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Aud</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
						</tr>
						</thead>
						<tbody>
						<tr v-for="st in stationSummaryData" :key="st.id">
							<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{st.name}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{st.nSpots}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{st.impacts}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{st.grossRating}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{st.reach}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{st.reachPct}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{st.avgFrequency}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{st.avgAud}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{st.cost}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{st.cpm}}</td>
						</tr>
						</tbody>
					</table>
					<BlankExcelTwoRows></BlankExcelTwoRows>
				</div>

				<div class="table-holder">
					<table v-if="chosenMarketData && stationWeekDataTable && stationWeekDataTable.length > 0" class="std-tbl">
						<thead>
						<tr>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Station</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Week Number</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">N Spots</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Impacts</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">GRPs</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cume</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cume %</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Frequency</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Aud</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
						</tr>
						</thead>
						<tbody>
						<tr v-for="mk in stationWeekDataTable" :key="mk.stationId">
							<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.name}}</td>
							<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{getDisplayWeek(mk)}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.nSpotsCumulative)}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.impactsCumulative)}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.grossRating}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.reachCumulative}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.reachPctCumulative}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.avgFrequencyCumulative}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.avgAudCumulative}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.costCumulative)}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.cpmCumulative}}</td>
						</tr>
						</tbody>
					</table>
					<BlankExcelTwoRows></BlankExcelTwoRows>
				</div>

				<div class="table-holder">
					<table v-if="chosenMarketData && audiotypesummarydata && audiotypesummarydata.length > 0 && demodata && demodata.audioType === 0" class="std-tbl" style="margin-top: 30px;">
						<thead>
						<tr>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Radio Type</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Impacts</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">GRPs</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cume</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cume %</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Frequency</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Aud</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
							<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
						</tr>
						</thead>
						<tbody>
						<tr v-for="at in audiotypesummarydata" :key="at.id">
							<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.name}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.impacts}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.grossRating}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.reach}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.reachPct}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.avgFrequency}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.avgAud}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.cost}}</td>
							<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.cpm}}</td>
						</tr>
						</tbody>
					</table>
					<BlankExcelTwoRows></BlankExcelTwoRows>
				</div>
			</div>
			<div class="weeksplithold">
				<table class="excel-table-info">
					<tbody>
					<tr>
						<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">Splits by Week in {{chosenMarketData.marketName}}</td>
					</tr>
					<tr><td></td><td></td></tr>
					</tbody>
				</table>
				<div v-if="chosenMarketData && chosenMarketData.weekSplits" style="width: 100%; height: 340px; margin-bottom: 20px;">
					<RFWeeklyCume :sourcedata="chosenMarketData.weekSplits" :showactualweeks="showactualweeks"></RFWeeklyCume>
				</div>

				<div style="margin-bottom: 30px;" class="table-holder">
					<RFWeekSplitTable v-if="chosenMarketData && chosenMarketData.weekSplits"
						:weeksplitdata="chosenMarketData.weekSplits" :stationcol="false" :showactualweeks="showactualweeks"
					></RFWeekSplitTable>
				</div>

				<div class="table-holder">
					<RFNetworkComboResultTable v-if="demodata && demodata.combos" :splitdata="demodata.combos"
						:marketid="chosen_market_id"
					></RFNetworkComboResultTable>
				</div>
			</div>
		</div>

		<div v-if="dataorspotsview === 'spotslist' || (exportingtoexcel === true && this.store.rfConfig.schedule.length < this.store.doNotExportSpotsListInRFResultsIfMoreThanNSpots)">
			<table class="excel-table-info">
				<tbody>
				<tr>
					<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">Spot Schedule in {{chosenMarketData.marketName}}</td>
				</tr>
				<tr><td></td><td></td></tr>
				</tbody>
			</table>
			<div class="table-holder">
				<RFSpotScheduleTable v-if="chosenMarketData && chosenMarketData.schedule" :spotplays="chosenMarketData.schedule" :showactualweeks="showactualweeks"></RFSpotScheduleTable>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import WarningsBox from "@/components/result-tables/WarningsBox";
import RFSummaryTable from "@/components/result-tables/RFSummaryTable";
import RFNPlusExposureTable from "@/components/result-tables/RFNPlusExposureTable";
import BlankExcelTwoRows from "@/components/result-tables/BlankExcelTwoRows";
import RFWeekSplitTable from "@/components/result-tables/RFWeekSplitTable";
import RFSpotScheduleTable from "@/components/result-tables/RFSpotScheduleTable";
import RFNPlusChart from "@/components/rfcharts/RFNPlusChart.vue";
import RFStationPie from "@/components/rfcharts/RFStationPie.vue";
import RFWeeklyCume from "@/components/rfcharts/RFWeeklyCume.vue";
import RFNetworkComboResultTable from "@/components/result-tables/RFNetworkComboResultTable.vue";

export default {
	name: "RFMarketInfoPage",
	components: {
		RFNetworkComboResultTable,
		RFWeeklyCume,
		RFStationPie,
		RFNPlusChart,
		RFSpotScheduleTable,
		RFWeekSplitTable,
		BlankExcelTwoRows,
		RFNPlusExposureTable,
		RFSummaryTable,
		WarningsBox
	},
	props: {
		chosen_market_id: Number,
		demodata: Object,
		dataorspotsview: String,
		exportingtoexcel: Boolean,
		audiotypesummarydata: Array,
		showactualweeks: {
			type: String,
			default: 'generic',
		},
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {
		chosenMarketData() {
			if(this.demodata.marketSplits && this.chosen_market_id) {
				return this.demodata.marketSplits.find(item => item.marketId === this.chosen_market_id)
			}
			return null
		},
		// audioTypeSummaryData() {
		// 	let arr = []
		// 	for(let i=0; i<=2; i++) {
		// 		if(this.store.returnedData.find(item => item.demoId === this.demodata.demoId && item.audioType === i)) {
		// 			let dd = this.store.returnedData.find(item => item.demoId === this.demodata.demoId && item.audioType === i)
		// 			let d = dd.marketSplits.find(item => item.marketId === this.chosen_market_id)
		// 			arr.push({
		// 				id: i,
		// 				name: this.getAudioTypeNameFromId(i),
		// 				impacts: this.numdisplay(d.impacts),
		// 				reach: this.numdisplay(d.reach),
		// 				reachPct: this.numdisplayNdp(d.reachPct,1),
		// 				cpm: d.cpm,
		// 				cost: this.numdisplay(d.cost),
		// 				avgFrequency: d.avgFrequency,
		// 				avgAud: this.numdisplay(d.avgAud),
		// 				grossRating: this.numdisplayNdp(d.grossRating,1),
		// 			})
		// 		}
		// 	}
		// 	return arr
		// },
		stationResultsForMarket() {
			let p = this.store.returnedData.find(item => item.demoId === this.demodata.demoId && item.audioType === this.demodata.audioType)
			if(p && p.stationResults) {
				return p.stationResults.filter(item => item.marketId === this.chosen_market_id)
			}
			return []
		},
		stationWeekDataTable() {
			let arr = []
			let p = this.store.returnedData.find(item => item.demoId === this.demodata.demoId && item.audioType === this.demodata.audioType)
			if(p && p.stationResults) {
				let stnarr = p.stationResults.filter(item => item.marketId === this.chosen_market_id)
				for(let stnres of stnarr) {
					for(let wk of stnres.weekSplits) {
						arr.push({
							id: stnres.stationId,
							name: this.getStationNameFromId(stnres.stationId),
							weekNumber: wk.weekNumber,
							actualWeekId: wk.actualWeekId,
							actualWeekStartDate: wk.actualWeekStartDate,
							actualWeekEndDate: wk.actualWeekEndDate,
							nSpots: wk.nSpots,
							nSpotsCumulative: wk.nSpotsCumulative,
							impacts: this.numdisplay(wk.impacts),
							impactsCumulative: this.numdisplay(wk.impactsCumulative),
							reach: this.numdisplay(wk.reach),
							reachCumulative: this.numdisplay(wk.reachCumulative),
							reachPct: this.numdisplayNdp(wk.reachPct, 1),
							reachPctCumulative: this.numdisplayNdp(wk.reachPctCumulative, 1),
							cpm: wk.cpm,
							cost: this.numdisplay(wk.cost),
							avgFrequency: wk.avgFrequency,
							avgFrequencyCumulative: wk.avgFrequencyCumulative,
							avgAud: this.numdisplay(wk.avgAud),
							avgAudCumulative: this.numdisplay(wk.avgAudCumulative),
							grossRating: this.numdisplayNdp(wk.grossRating, 1),
							costCumulative: this.numdisplay(wk.costCumulative),
							cpmCumulative: wk.cpmCumulative,
						})
					}
				}
			}
			return arr
		},
		stationSummaryData() {
			let arr = []
			let p = this.store.returnedData.find(item => item.demoId === this.demodata.demoId && item.audioType === 0)
			if(p && p.stationResults) {
				let stnarr = p.stationResults.filter(item => item.marketId === this.chosen_market_id)
				for(let stnres of stnarr) {
					arr.push({
						id: stnres.stationId,
						name: this.getStationNameFromId(stnres.stationId),
						nSpots: stnres.nSpots,
						impacts: this.numdisplay(stnres.impacts),
						reach: this.numdisplay(stnres.reach),
						reachPct: this.numdisplayNdp(stnres.reachPct, 1),
						cpm: stnres.cpm,
						cost: this.numdisplay(stnres.cost),
						avgFrequency: stnres.avgFrequency,
						avgAud: this.numdisplay(stnres.avgAud),
						grossRating: this.numdisplayNdp(stnres.grossRating, 1),
					})
				}
			}
			return arr
		},
		firstWeekDisplayName() {
			if(this.showactualweeks === 'actual') return this.stationWeekDataTable[0].actualWeekId
			else if(this.showactualweeks === 'wcdate') return this.stationWeekDataTable[0].actualWeekStartDate
			else if(this.showactualweeks === 'wcdatedmy') return this.convertYMDtoDMY(this.stationWeekDataTable[0].actualWeekStartDate)
			return 'W'+this.stationWeekDataTable[0].weekNumber
		},
	},
	methods: {
		getDisplayWeek(mk) {
			let out = this.firstWeekDisplayName + ' to '
			if(this.showactualweeks === 'actual') {
				return out + mk.actualWeekId
			}
			else if(this.showactualweeks === 'wcdate') return out + mk.actualWeekEndDate
			else if(this.showactualweeks === 'wcdatedmy') return out + this.convertYMDtoDMY(mk.actualWeekEndDate)
			return out + 'W'+mk.weekNumber
		}
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.topsection {
	display: flex;
	gap: 50px;
	padding: 0 0 25px 0;
}
.nplusSection {
	display: flex;
	gap: 50px;
	padding: 0 0 25px 0;
}
.nplusChartHold {
	min-width: 450px;
}
.minor-heading {
	margin: 8px 16px 5px;
	font-size: 13px;
	font-weight: bold;
}
.halvedsections {
	display: flex;
	gap: 50px;
	padding: 15px 0;
}
.rfMarketPieHold {
	width: 100%;
	max-width: 700px;
	height: 340px;
	margin-bottom: 20px;
}
.table-holder {
	width: 100%;
	overflow-x: auto;
}

@media screen and (max-width: 1489px) {
	.halvedsections {
		flex-direction: column;
	}
}

@media screen and (max-width: 1250px) {
	.topsection {
		display: block;
	}
	.topsection .overview-table {
		width: 100%;
	}
	.nplusSection {
		display: flex;
		gap: 50px;
		padding: 40px 0 25px 0;
	}
}

@media screen and (max-width: 1190px) {
	.halvedsections {
		display: block;
		padding: 15px 0;
	}
	.weeksplithold {
		margin-top: 30px;
	}
}
@media screen and (max-width: 899px) {
	.topsection .overview-table,
	.topsection .nplusTable {
		width: 50%
	}
	.nplusSection {
		display: block;
	}
	.nplusChartHold {
		height: 300px;
		margin-top: 40px;
	}
	.nplusChartHold {
		min-width: unset;
	}
}
</style>