<template>
	<div>
		<div style="font-size: 12px; text-transform: uppercase; padding: 5px 0; font-weight: bold;" class="">
			{{folderob.name}}
			<i v-if="filteredDaypartsInFolder.length === 0 && folderob.id > 0" class="mdi mdi-close" @click="$emit('deletefolder')" style="color: #B00; cursor: pointer;"></i>
		</div>
		<div v-for="c in filteredDaypartsInFolder" :key="c.id" class="jli">
			<div>
				<span class="jname">{{c.name}}</span>
			</div>
			<div class="" style="display: flex; justify-content: space-between; width: 140px;">
				<span class="jope" @click="editDaypart(c.id)" title="Edit daypart">EDIT</span>
				<span class="jop" @click="changeFolder(c)" title="Change folder">FOLDER</span>
				<span class="jdel" @click="$emit('deletedaypart', c.id)" title="Delete daypart">DELETE</span>
			</div>
		</div>

		<div v-if="showingFolderChooser" class="loader-overlay">
			<div class="popup-container folder-chooser">
				<p class="bold" style="margin: 10px 0;">Choose Folder</p>
				<div v-if="!showAddFolder">
					<select v-model="chosenFolderId" class="selectbox">
						<option value="0">Unassigned</option>
						<option v-for="df in store.daypartfolders" :key="df.id" :value="df.id">{{df.name}}</option>
					</select>
					<span @click="saveToFolder" class="primary-button" style="margin: 0px 10px 0px 10px; display: inline-block;">Save</span>
					<span @click="showingFolderChooser = false" class="primary-button cancel-button" style="margin: 0; display: inline-block;">Cancel</span>
					<span @click="showAddFolder = true" class="primary-button" style="margin: 0px 20px 0px 20px; display: inline-block;">New Folder</span>
				</div>
				<div style="padding: 0 0 20px 0;">
					<div v-if="showAddFolder" style="display: inline-block;">
						<input type="text" style="width: 300px; padding: 5px;" v-model="newFolderName">
						<span @click="createNewFolder" class="primary-button" style="margin: 0px 10px 0px 10px; display: inline-block;">Save</span>
						<span @click="showAddFolder = false" class="primary-button cancel-button" style="margin: 0; display: inline-block;">Cancel</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "DaypartManageFolder",
	components: {},
	mixins: [globalMixin],
	props: {
		dayparts: Array,
		folderob: Object,
	},
	data: function () {
		return {
			store: store,
			showingFolderChooser: false,
			chosenFolderId: 0,
			activeDaypartId: 0,

			showAddFolder: false,
			newFolderName: '',
		}
	},
	computed: {
		filteredDaypartsInFolder() {
			return this.dayparts.filter(item => item.infolder === this.folderob.id);
		},
	},
	methods: {
		createNewFolder() {
			if(this.newFolderName.length > 0) {
				let self = this;
				axios.post(
					"/post/daypart-folder-create.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						name: self.newFolderName,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
					}
					if(ret.daypartfolders) self.store.daypartfolders = ret.daypartfolders;
					self.showAddFolder = false;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		changeFolder(cob) {
			this.chosenFolderId = cob.infolder;
			this.activeDaypartId = cob.id;
			this.showingFolderChooser = true;
		},
		saveToFolder() {
			if(this.activeDaypartId > 0) {
				let self = this;
				axios.post(
					"/post/daypart-change-folder.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						daypartid: self.activeDaypartId,
						folderid: self.chosenFolderId,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
					}
					self.showingFolderChooser = false;
					if(ret.daypartfolders) self.store.daypartfolders = ret.daypartfolders;
					if(ret.dayparts) self.store.dayparts = ret.dayparts;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.popup-container.folder-chooser {
	height: 100px;
}

</style>