<template>
	<div>
		<DaypartViewFolder v-for="folder in folderList" :key="folder.id"
			:folderob="folder" :dayparts="dayparts"
			v-on:toggledaypart="$emit('toggledaypart', $event)"
		>
		</DaypartViewFolder>
	</div>
</template>

<script>
import DaypartViewFolder from "@/components/dayparts/DaypartViewFolder"
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "DaypartViewGroup",
	components: {
		DaypartViewFolder,
	},
	mixins: [globalMixin],
	props: {
		dayparts: Array,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		folderList() {
			let out = [];
			let folderids = [0]; //remember zero is first
			for(let i=0; i<this.dayparts.length; i++) {
				if(!folderids.includes(this.dayparts[i].infolder)) {
					folderids.push(this.dayparts[i].infolder);
					let nm = 'Daypart '+this.dayparts[i].infolder;
					let fold = this.store.daypartfolders.find(item => item.id === this.dayparts[i].infolder);
					if(fold) nm = fold.name;
					out.push({id: this.dayparts[i].infolder, name: nm});
				}
			}
			out = out.sort(function(a, b){ //sort by name
				if(a.name < b.name) { return -1; }
				if(a.name > b.name) { return 1; }
				return 0;
			});
			out.push({id: 0, name: 'Unsorted'});
			return out;
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>