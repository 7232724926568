import { reactive } from 'vue'

let isDevServer = false;
if (window.location.origin.indexOf('localhost') > -1 || window.location.origin.indexOf('.test') > -1) {
	isDevServer = true;
}

// const defaultRfConfig = {
// 	type: 'precampaign', //dayparts_standard, qtrhr, post_times, dayparts_full, goldstdcsv
// 	schedule: [],
// 	ratecard: {},
// 	ratecard_id: null,
// 	campaignLines: [],
// 	randomiseSpotTimesWithinCombos: false,
// }

// const defaultViewPrefs = {
// 	cumePrecisionLevel: 1, //1, 100, 1000
// 	commasInData: true,
// 	showGroupedMarket: true,
// }

const xlborder = {
	top: {style: 'thin', color: {argb: 'FF888888'}},
	left: {style: 'thin', color: {argb: 'FF888888'}},
	bottom: {style: 'thin', color: {argb: 'FF888888'}},
	right: {style: 'thin', color: {argb: 'FF888888'}}
}

export const store = reactive({
	urlroot: null,
	kalert: {
		shown: false,
		text: "",
		type: 'danger',
	},
	user: {
		id: 0,
		email: '',
		token: '',
		isadmin: null,
	},
	usercreds: {
		id: 0,
		token: '',
	},
	sessToken: '',
	isDevServer: isDevServer,
	logosRoot: '/public-assets/',
	getparams: {},

	reportType: null, //normal, reachFrequency, loyalty
	activeTab: null,
	marketViewListChooser: 'surveyed',
	showPrivateStations: false,
	showDabStations: true,

	//retrieved from get-initial-objects
	markets: [],
	surveys: [],
	stations: [],
	stationcombos: [],
	demographics: [],
	demographic_attributes: [],
	demographic_groups: [],
	dayparts: [],
	statistics: [],
	tvaggmkts: [],
	demofolders: [],
	combofolders: [],
	daypartfolders: [],
	jobs: [],
	jobfolders: [],
	xtraDemoKeysOk: [],
	gfkDemoKeysOk: [],
	metroEquivalentSurveyObs: [],
	relativeLatestSurveyObs: [],
	relativeLatestSMBAPMaps: [],

	// shortcutmarketTrigger: [],
	forceMenusClose: 0,

	selectionObs: {
		markets: [],
		surveys: [],
		stations: [],
		demographics: [],
		dayparts: [],
		statistics: [],
		places: [],
		qtrHrs: [],
		audioTypes: [],
	},

	rfTypePromptOverlayShown: false,
	nweeksrf: 1, //used by dayparts_standard etc
	precampaignWeeksShown: 4, //used by precampaign editor
	defaultRfCampaignWeeks: [], //set by App > mounted()
	// defaultRfConfig: defaultRfConfig,
	rfConfig: {},
	rfDaypartsStandardHandlingUpload: false,
	rfTotalSpots: 0, //for schedule summary, worked out with JS not server
	rfTotalCost: 0, //for schedule summary, worked out with JS not server
	rfTotalImpacts: 0, //for schedule summary, worked out with JS not server
	rfTotCpm: 0, //for schedule summary, worked out with JS not server
	rfScheduleDemoId: 48, //show schedule summary/GRPs by which demo
	rfScheduleWeeklySummary: [],
	rfScheduleEditRowIndex: null,
	rfStationToSurveyMaps: {}, //{g3201: 'ME202301', ...}
	rfQtrHrAuds: {}, //GRPs by qtr hour for survey
	doNotExportSpotsListInRFResultsIfMoreThanNSpots: 1000,
	runAsHighlights: 0,
	gfkMktIds: [110,111,112,113,114,14,19,72],
	gfkMetroMktIds: [110,111,112,113,114],

	shareableLinkSlug: '',

	cumeStatistics: ['cume','avgAud','exclusiveCume','universeEstimate','exclusiveCumeToSelected'],

	returnedData: [],
	returnedDataInputs: [],
	returnedDataSourcing: '',
	returnedWarnings: [],
	selectionsHaveChangedNotYetRun: true,
	showLoader: false,
	showGreenHighlights: null, //can be column or row
	resultTableShownIndex: 0,
	calcIsRunning: false,
	isFetchingQtrHrAuds: false,

	jobname: '',
	jobid: 0,
	jobManageOverlayShown: false,
	batchManageOverlayShown: false,
	combosManageOverlayShown: false,
	chartExplorerOverlayShown: false,
	comboEditOverlayShown: false,
	editComboId: 0,
	addToStationListAfterComboEdit: false, //when called from Stations screen
	demosManageOverlayShown: false,
	demoEditOverlayShown: false,
	editDemoId: 0,
	daypartManageOverlayShown: false,
	daypartEditOverlayShown: false,
	editDaypartId: 0,

	tableLayout: {},
	viewPreferences: {},

	splitByAudioType: false,
	audioTypes: [
		{id: 0, name: 'Total Radio', type: 'audioType'},
		{id: 1, name: 'AM/FM/DAB+ Only', type: 'audioType'},
		{id: 2, name: 'Streaming Only', type: 'audioType'},
		{id: 9, name: 'Not stated', type: 'audioType'},
	],
	splitByQuarterHours: false,
	qtrHrs: [],
	splitByPlaceOfListen: false,
	places: [
		{id: 0, name: 'All', type: 'place'},
		{id: 1, name: 'Home', type: 'place'},
		{id: 2, name: 'Car', type: 'place'},
		{id: 3, name: 'Work', type: 'place'},
		{id: 4, name: 'Elsewhere', type: 'place'},
		// {id: 9, name: 'Not stated', type: 'place'},
	],

	rfrowclipboard: null,
	rfweekclipboard: null,

	lookupQueryFailed: false,
	lookupQueryFailedMessage: '',

	netcolors: [
		{key: 'arn_k', col: '#e50076'},
		{key: 'arn', col: '#e50076'},
		{key: 'arn_p', col: '#EDCA62'},
		{key: 'sca_h', col: '#1bcfc9'},
		{key: 'sca', col: '#1bcfc9'},
		{key: 'sca_m', col: '#fc6402'},
		{key: 'abc_loc', col: '#000000'},
		{key: 'abc_j', col: '#f33e2a'},
		{key: 'nine', col: '#092e52'},
		{key: 'nova', col: '#eb1c24'},
		{key: 'nova_n', col: '#eb1c24'},
		{key: 'nova_s', col: '#005094'},
	],
	defaultColors: ['#3669B0','#003f5c','#42d534','#2f4b7c','#665191','#a05195','#d45087','#f95d6a','#ff7c43','#ffa600'],
	femaleMaleColors:  ['#e50076','#0055a5'],
	defaultColorCount: 0,

	//exceljs export formats
	xlfmt: {
		header: {font: {bold: true, name: 'Tahoma', size: 10}},
		boldborder: {
			font: {bold: true, name: 'Tahoma', size: 10},
			border: xlborder
		},
		textborder: {
			font: {name: 'Tahoma', size: 10},
			border: xlborder
		},
		numberborder: {
			font: {name: 'Tahoma', size: 10},
			// numFmt: '#',
			border: xlborder,
		},
		borderthead: {
			font: {bold: true, name: 'Tahoma', size: 10},
			border: xlborder,
			fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFEAEAEA'}}
		},
	},

})