<template>
	<div class="chooser">
		<div class="left-side">
			<div class="left-side-top">
				<div class="interiorTabsHolder">
					<slot name="interior_tabs"></slot>
				</div>
				<div class="filterbox-hold" :class="{filterboxtopborder : filterboxtopborder}">
					<input type="text" placeholder="Filter..." v-model="filterText" class="filterbox" />
				</div>
			</div>
			<div class="checkbox-list-hold" :class="{nointeriortabs : nointeriortabs}">
				<slot name="left_items"></slot>
			</div>
		</div>
		<div class="right-side sortable-list">
			<slot name="rightside"></slot>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "ListsChooser",
	components: {},
	props: {
		filterboxtopborder: Boolean,
		nointeriortabs: {
			type: Boolean,
			default: false
		},
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			filterText: null,
		}
	},
	computed: {},
	methods: {},
	watch: {
		filterText() {
			this.$emit('filterupdate', this.filterText)
		},
	},
	mounted() {

	}
}
</script>

<style scoped>
.chooser {
	display: flex;
}
.left-side, .right-side {
	height: calc(100vh - 200px);
	background-color: #FFF;
	box-shadow: 0 0 20px 5px rgba(100,100,100,0.1);
	/*border: 1px solid #DDD;*/
}
.left-side {
	width: 500px;
}
.right-side {
	margin-left: 50px;
	min-height: 500px;
	width: 500px;
	border: 1px solid #DDD;
	background-color: #FFFFFF;
	overflow-y: auto;
}
.filterbox-hold {
	padding: 10px 0;
	display: flex;
	justify-content: center;
	border-left: 1px solid #DDD;
	border-right: 1px solid #DDD;
	border-bottom: 1px solid #DDD;
	background: var(--greylight);
}
.filterbox {
	width: 95%;
}
.interiorTabsHolder {
	display: flex;
	justify-content: space-between;
	width: 100%
}
.left-side-top {

}
.checkbox-list-hold {
	height: calc(100% - 82px);
	padding: 15px;
	overflow-y: auto;
	border: 1px solid #DDD;
	border-top: none;
}
.checkbox-list-hold.nointeriortabs {
	height: calc(100% - 51px);
}
.filterboxtopborder {
	border-top: 1px solid #DDD;
}
</style>