<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Choose your R&F scenario</span>
				<span class="closer" @click="store.rfTypePromptOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>

			<div class="popup-insides">

				<div class="selection-row">
					<div class="large-tile-button" @click="setTypeAndGo('preCampaignSchedule')">
						<div class="tile-inside">
							<div class="tile-icon-hold">
								<i class="mdi mdi-calendar-arrow-right"></i>
							</div>
							<p>Pre Campaign Schedule Builder</p>
						</div>
					</div>
					<p style="text-align: left;">Pre campaign - build a schedule by station/combo x dayparts x weeks.  Let our spot generator place spots for you.</p>
				</div>
				<div class="selection-row">
					<div class="large-tile-button" @click="setTypeAndGo('preCampaignManual')">
						<div class="tile-inside">
							<div class="tile-icon-hold">
								<i class="mdi mdi-clock-plus-outline"></i>
							</div>
							<p>Pre Campaign Manual Spots</p>
						</div>
					</div>
					<p>Pre campaign - manually add spots one by one by quarter hour.</p>
				</div>
				<div class="selection-row">
					<div class="large-tile-button" @click="setTypeAndGo('post_times')">
						<div class="tile-inside">
							<div class="tile-icon-hold">
								<i class="mdi mdi-format-list-bulleted"></i>
							</div>
							<p>Post Campaign Aquira CSV Upload</p>
						</div>
					</div>
					<p>Post campaign - upload an Aquira spot play times export</p>
				</div>
				<div class="selection-row">
					<div class="large-tile-button" @click="setTypeAndGo('goldstdcsv')">
						<div class="tile-inside">
							<div class="tile-icon-hold">
								<i class="mdi mdi-file-delimited-outline"></i>
							</div>
							<p>Gold Standard CSV Upload</p>
						</div>
					</div>
					<p>Gold Standard CSV import - upload a GFK RFIN CSV</p>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
// import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "RFTypePrompt",
	components: {

	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {
		setTypeAndGo(type) {
			let rftype = 'precampaign'
			let targetTab = 'Markets'
			if(type === 'preCampaignSchedule') {
				targetTab = 'Markets'
			}
			else if(type === 'preCampaignManual') {
				targetTab = 'Markets'
				rftype = 'qtrhr'
			}
			else if(type === 'post_times') {
				targetTab = 'Schedule'
				rftype = type
			}
			else if(type === 'goldstdcsv') {
				targetTab = 'Schedule'
				rftype = type
			}
			this.setReportType('reachFrequency', targetTab)
			this.store.rfConfig.type = rftype
			this.store.rfTypePromptOverlayShown = false
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.selection-row {
	display: flex;
	align-items: center;
}
.large-tile-button {
	flex-shrink: 0;
}
</style>