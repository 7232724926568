<template>
	<div>
		<table v-if="spotplays && spotplays.length > 0" class="std-tbl">
			<thead>
				<tr>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Spot Number</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Week Number</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Market</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Play Date</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Station</td>
<!--					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Day Of Week</td>-->
<!--					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Std Daypart</td>-->
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Radio Type</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Play Time</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Spot Type</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Reach / Impacts</td>
					<td v-if="!summary" data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Rtg %</td>
					<td v-if="!summary" data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">GRP %</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(sp, ind) in activePaginatedSpotplays" :key="ind">
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{ind+(activePage*displayRowLimit)+1}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{getWeekNumber(sp)}}</td>
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{getMarketNameFromId(sp.marketId)}}</td>
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">
						<span v-if="sp.playDate && sp.playDate.length > 0">{{sp.playDate}}</span>
						<span v-else-if="sp.dayOfWeek && sp.dayOfWeek.length > 0">{{ucfirst(sp.dayOfWeek)}}</span>
					</td>
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.stationName}}</td>
<!--					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{ucfirst(sp.dayOfWeek)}}</td>-->
<!--					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.daypart}}</td>-->
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{getAudioTypeNameFromId(sp.audioType)}}</td>
<!--					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.playTime}} {{sp.qtrHrStartTime}}</td>-->
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.playTime}}</td>
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.spotClass}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(sp.reach)}}</td>
					<td v-if="!summary" data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(sp.reachPct,1)}}</td>
					<td v-if="!summary" data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(sp.cumulativeGrpPct,1)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.spotRate}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{sp.cpm}}</td>
				</tr>
			</tbody>
		</table>
		<div v-if="maxPages > 1" style="margin: 20px 0;">
			<span style="margin-right: 10px; font-size: 12px;">Page</span>
			<select v-model="activePage">
				<option v-for="n in maxPages" :key="n" :value="n-1">{{n}}</option>
			</select>
		</div>
		<BlankExcelTwoRows></BlankExcelTwoRows>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlankExcelTwoRows from "@/components/result-tables/BlankExcelTwoRows";

export default {
	name: "RFSpotScheduleTable",
	components: {BlankExcelTwoRows},
	props: {
		spotplays: Array,
		summary: {
			type: Boolean,
			default: false,
		},
		showactualweeks: {
			type: String,
			default: 'generic',
		},
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			displayRowLimit: 100,
			activePage: 0,
		}
	},
	computed: {
		paginateStart() {
			return this.activePage * this.displayRowLimit
		},
		paginateEnd() {
			return this.paginateStart + this.displayRowLimit
		},
		maxPages() {
			return Math.ceil(this.spotplays.length / this.displayRowLimit)
		},
		activePaginatedSpotplays() {
			return this.spotplays.slice(this.paginateStart, this.paginateEnd)
		},
	},
	methods: {
		getWeekNumber(sp) {
			if(this.showactualweeks === 'actual') return sp.actualWeekId
			else if(this.showactualweeks === 'wcdate') return sp.actualWeekStartDate
			else if(this.showactualweeks === 'wcdatedmy') return this.convertYMDtoDMY(sp.actualWeekStartDate)
			return sp.weekNumber
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>