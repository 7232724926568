<template>
	<div>
		<TopTabBar :hidebelowtabs="true"></TopTabBar>
		<div class="body-hold hide-below-tabs">
			<div class="view-holder" :class="{showingTableLayout : reportType === 'normal'}">
				<div style="display: flex; gap: 30px; width: 100%;">
					<div v-if="reportType === 'normal'" class="layout-controller">
						<p style="font-size: 12px; margin: 10px 0;">Move the items to adjust the results table layout.</p>
						<div class="layout-control-group">
							<p class="bold">Tables</p>
							<draggable class="list-group" :list="store.tableLayout.tables" group="tableitems" itemKey="id" v-bind="dragOptions">
								<template #item="{element, index}">
									<div class="list-group-item" :data-ind="index">{{ element.name }}</div>
								</template>
							</draggable>
						</div>
						<div class="layout-control-group">
							<p class="bold">Columns</p>
							<draggable class="list-group" :list="store.tableLayout.columns" group="tableitems" itemKey="id" v-bind="dragOptions">
								<template #item="{element, index}">
									<div class="list-group-item" :data-ind="index">{{ element.name }}</div>
								</template>
							</draggable>
						</div>
						<div class="layout-control-group">
							<p class="bold">Rows</p>
							<draggable class="list-group" :list="store.tableLayout.rows" group="tableitems" itemKey="id" v-bind="dragOptions">
								<template #item="{element, index}">
									<div class="list-group-item" :data-ind="index">{{ element.name }}</div>
								</template>
							</draggable>
						</div>
					</div>
					<div v-if="store.selectionsHaveChangedNotYetRun === false" :class="{layout_right_side_normal : reportType === 'normal'}">
						<div style="padding: 30px 0 10px;">
							<div v-if="reportType === 'normal'" style="margin-bottom: 20px;">
								<ResultTableGroup v-if="store.returnedData"></ResultTableGroup>
							</div>
							<div v-else-if="reportType === 'reachFrequency'" style="margin-bottom: 20px;">
								<RFResultContentPage></RFResultContentPage>
							</div>
							<div v-else-if="reportType === 'stationP1'" style="margin-bottom: 20px;">
								<P1ReportPage></P1ReportPage>
							</div>
							<div v-else>
								<QueryErrorNote v-if="store.lookupQueryFailed === true"></QueryErrorNote>
								<div>
									<div v-if="store.returnedData.length > 0" class="result-table-nav">
										<button class="resnavbtn" @click="navToPrevTable">&lt;</button>
										<span class="tsp"> Table {{activeTableNum}} of {{store.returnedData.length}} </span>
										<button class="resnavbtn" @click="navToNextTable">&gt;</button>
									</div>
									<div id="excel-basic-wrapper">
										<div v-for="(item, index) in store.returnedData" :key="index">
											<ResultTableBasic :objin="item" :class="{hidden : index !== resultTableShownIndex}"></ResultTableBasic>
											<table style="display: none"><tr><td></td></tr><tr><td></td></tr></table>
										</div>
									</div>
									<div class="primary-button" @click="exportAllToExcel()" style="margin-bottom: 10px;">
										<i class="mdi mdi-file-excel-outline"></i>
										Export To Excel
									</div>
								</div>
							</div>
						</div>
						<div v-if="store.returnedDataSourcing.length > 0" style="padding-bottom: 20px;">
							<p style="font-size: 12px;">Source: {{store.returnedDataSourcing}}</p>
						</div>
					</div>
					<div v-else style="padding: 30px 0;">
						<p v-if="selectionsAreAllValid === false">Make your selections then click Process to fetch results.</p>
						<p v-if="selectionsAreAllValid === true">Click the Process button fetch results.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ResultTableBasic from "@/components/result-tables/ResultTableBasic";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ResultTableGroup from "@/components/result-tables/ResultTableGroup";
import draggable from 'vuedraggable'
import RFResultContentPage from "@/components/result-tables/RFResultContentPage";
import TableToExcel from "@linways/table-to-excel";
import TopTabBar from "@/components/TopTabBar.vue";
import QueryErrorNote from "@/components/QueryErrorNote.vue";
import P1ReportPage from "@/components/result-tables/P1ReportPage.vue";
export default {
	name: "ResultsView",
	components: {
		P1ReportPage,
		QueryErrorNote,
		TopTabBar,
		RFResultContentPage,
		ResultTableGroup,
		ResultTableBasic,
		draggable,
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,

			activeTableNum: 1,
		}
	},
	computed: {
		dragOptions() {
			return {
				animation: 200,
				disabled: false,
				ghostClass: "ghost-drag-result-layout"
			}
		},
		selectionsAreAllValid() {
			if(this.store.selectionObs.markets.length === 0) return false;
			if(this.store.selectionObs.surveys.length === 0) return false;
			if(this.reportType === 'normal') {
				if(this.store.selectionObs.statistics.length === 0) return false;
				if(this.store.selectionObs.dayparts.length === 0) return false;
			}
			else if(this.reportType === 'reachFrequency') {
				if(this.store.rfConfig.schedule.length === 0) return false
			}
			let needsStations = ['normal','reachFrequency'];
			if(needsStations.includes(this.reportType)) {
				if(this.store.selectionObs.stations.length === 0) return false;
			}
			let needsDemos = ['normal','reachFrequency','loyalty','ebbAndFlow'];
			if(needsDemos.includes(this.reportType)) {
				if(this.store.selectionObs.demographics.length === 0) return false;
			}
			return true;
		},
		reportType() {
			return this.store.reportType;
		},
		resultTableShownIndex() {
			return this.activeTableNum - 1
		}
	},
	methods: {
		exportAllToExcel() { //for basic tables
			this.isExporting = true
			setTimeout(function() {
				let content = document.querySelector("#excel-basic-wrapper")
				let contentT = content.cloneNode(true)
				contentT.innerHTML = contentT.innerHTML.replace(/,/g,''); //replaces , globally
				TableToExcel.convert(contentT, {
					name: "Audology Export.xlsx",
					sheet: {
						name: 'Sheet 1'
					}
				});
				this.isExporting = false
			}.bind(this), 500)
		},
		navToPrevTable() {
			if(this.activeTableNum > 1) this.activeTableNum--
		},
		navToNextTable() {
			let tot = this.store.returnedData.length
			if(this.activeTableNum < tot) this.activeTableNum++
		}
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.view-holder {
	margin: 0 20px;
	padding: 0 40px;
}
.view-holder.showingTableLayout {
	padding: 0;
}
.tsp {
	display: inline-block;
	margin-top: 2px;
}
.layout-controller {
	background-color: var(--greylight);
	width: 180px;
	min-width: 180px;
	padding: 10px;
	float: left;
}
.layout-control-group {
	border-top: 1px solid var(--greymid);
	padding: 10px 0;
	height: 160px;
	font-size: 12px;
}
.layout_right_side_normal {
	width: calc(100% - 210px);
	float: right;
	overflow-x: hidden;
}
.list-group-item {
	padding: 5px;
	cursor: move;
	margin: 3px 0;
	background-color: #FFF;
}
</style>