<template>
	<div class="processbtn" @click="getResults">
		<i class="mdi mdi-play"></i>
		Process
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "GetResultsButton",
	components: {},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.processbtn {
	cursor: pointer;
	text-transform: uppercase;
	padding: 10px 25px;
	font-size: 13px;
	margin: 0 30px 0 30px;
	border: none;
	border-radius: 5px;
	background-color: var(--bluedark);
	transition: background-color 0.3s ease-in-out;
	color: #FFF;
}
.processbtn:hover {
	background-color: var(--bluelighthover);
}
</style>