<template>
	<div>
		<span class="primary-button" @click="upload(store.rfConfig.type)">
			Paste From Excel/CSV
		</span>
		<PasteWindowModal v-if="pasteOverlayShown" v-on:close="pasteOverlayShown = false" v-on:receive="updateFromPaster($event)"></PasteWindowModal>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import PasteWindowModal from "@/components/overlays/PasteWindowModal";

export default {
	name: "ScheduleUploader",
	components: {PasteWindowModal},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			uploadType: null,
			pasteOverlayShown: false,

		}
	},
	computed: {},
	methods: {
		upload(uploadType) {
			this.uploadType = uploadType
			this.pasteOverlayShown = true
		},
		updateFromPaster(indata) {
			let stationsNeeded = []
			let spotschedule = [];
			this.store.selectionObs.markets = []
			this.store.selectionObs.stations = []
			let lines = indata.split("\n")
			let numberOfWeeks = 0
			for(let line of lines) { //each line is a station for a week
				let cells = line.split("\t")
				if (cells[0].length > 0 && cells[0].toLowerCase().includes('station') === false) { //ignore header row if it exists
					let startCellInd = 0
					let stnName = ''
					let stncode = null
					let spotItem = {}
					if(this.uploadType === 'qtrhr') {
						if(cells.length === 8) { //we don't have station name column - first col is stid
							startCellInd = -1
						}
						else stnName = cells[0]
						stncode = cells[startCellInd+1].trim()
						if(!stationsNeeded.includes(stncode)) stationsNeeded.push(stncode)
						if(stnName.length === 0) stnName = this.getStationNameFromId(stncode)
						spotItem = {
							stationName: stnName,
							stationId: stncode,
							weekNumber: parseInt(cells[startCellInd+2]),
							dayOfWeek: cells[startCellInd+3],
							playTime: cells[startCellInd+4],
							audioType: parseInt(cells[startCellInd+5]),
							spotRate: parseFloat(cells[startCellInd+6]),
							ratecardRate: parseFloat(cells[startCellInd+7]),
							spotClass: cells[startCellInd+8]
						}
					}
					// else if(this.uploadType === 'dayparts_full') {
					// 	stnName = cells[0]
					// 	stncode = cells[1].trim()
					// 	let ncols = cells.length
					// 	if(!stationsNeeded.includes(stncode)) stationsNeeded.push(stncode)
					// 	if(stnName.length === 0) stnName = this.getStationNameFromId(stncode)
					// 	spotItem = {
					// 		stationName: stnName,
					// 		stationId: stncode,
					// 		dayOfWeek: cells[2],
					// 		daypart: cells[3],
					// 		audioType: parseInt(cells[4]),
					// 		spotRate: parseFloat(cells[5]),
					// 		ratecardRate: parseFloat(cells[6]),
					// 		spotClass: cells[7],
					// 		spotsByWeek: []
					// 	}
					// 	for(let cn=0; cn<ncols; cn++) {
					// 		if(cn > 7) { //7 is index of last column before week spots cols
					// 			let myweeknum = cn - 7
					// 			spotItem.spotsByWeek.push({weekNumber: myweeknum, nSpots: cells[cn]})
					// 			numberOfWeeks = myweeknum
					// 		}
					// 	}
					// }
					// else if(this.uploadType === 'dayparts_standard') {
					// 	stnName = cells[0]
					// 	stncode = cells[1].trim()
					// 	let ncols = cells.length
					// 	if(!stationsNeeded.includes(stncode)) stationsNeeded.push(stncode)
					// 	if(stnName.length === 0) stnName = this.getStationNameFromId(stncode)
					// 	spotItem = {
					// 		stationName: stnName,
					// 		stationId: stncode,
					// 		daypart: cells[2],
					// 		spotRate: parseFloat(cells[3]),
					// 		spotClass: cells[4],
					// 		spotsByWeek: []
					// 	}
					// 	for(let cn=0; cn<ncols; cn++) {
					// 		if(cn > 4) { //4 is index of last column before week spots cols
					// 			let myweeknum = cn - 4
					// 			spotItem.spotsByWeek.push({weekNumber: myweeknum, nSpots: cells[cn]})
					// 			numberOfWeeks = myweeknum
					// 		}
					// 	}
					// }
					// else if(this.uploadType === 'post_times') {
					// 	if(cells.length === 13) { //we don't have station name column - first col is stid
					// 		startCellInd = -1
					// 	}
					// 	else stnName = cells[0]
					// 	stncode = cells[startCellInd+1].trim()
					// 	if(!stationsNeeded.includes(stncode)) stationsNeeded.push(stncode)
					// 	if(stnName.length === 0) stnName = this.getStationNameFromId(stncode)
					// 	spotItem = {
					// 		stationName: stnName,
					// 		stationId: stncode,
					// 		playDate: cells[startCellInd+2],
					// 		playTime: cells[startCellInd+3], //actual aired time
					// 		spotDuration: parseInt(cells[startCellInd+4]),
					// 		audioType: parseInt(cells[startCellInd+5]),
					// 		spotRate: parseFloat(cells[startCellInd+6]),
					// 		spotClass: cells[startCellInd+7],
					// 		keyNumber: cells[startCellInd+8],
					// 		campaign: cells[startCellInd+9],
					// 		bookedDaypart: cells[startCellInd+10],
					// 		ratecardRate: parseFloat(cells[startCellInd+11]),
					// 		product: cells[startCellInd+12],
					// 		airedDaypart: cells[startCellInd+13],
					// 	}
					// }
					spotschedule.push(spotItem)
				}
			}
			if(spotschedule.length > 0) {
				let mktsNeeded = []
				let myStations = this.store.stations.filter(item => stationsNeeded.includes(item.id))
				for(let stn of myStations) {
					if(!mktsNeeded.includes(stn.mktid)) mktsNeeded.push(stn.mktid)
				}
				this.store.selectionObs.markets = this.store.markets.filter(item => mktsNeeded.includes(item.id))
				//if there is no survey already selected then select the latest survey for each required market
				if(this.store.selectionObs.surveys.length === 0) {
					let survs = []
					let lsurv = null
					for (let m of this.store.selectionObs.markets) {
						lsurv = this.store.surveys.find(item => item.id === m.latestSurveyId)
						if (lsurv) survs.push(lsurv)
						else {
							let latest = 0
							let mktsurvs = this.store.surveys.filter(item => item.mktid === m.id)
							for (let ms of mktsurvs) {
								if (latest < ms.reldateUnix) {
									lsurv = ms
									latest = ms.reldateUnix
								}
							}
						}
					}
					if (lsurv) {
						this.store.selectionObs.surveys = [
							{id: 'gr_1', name: 'R&F Group', survobs: survs}
						]
					}
				}
				this.store.selectionObs.stations = this.store.stations.filter(item => myStations.some(st => st.id === item.id))
				this.store.rfConfig.type = this.uploadType
				if(numberOfWeeks > 0) this.store.nweeksrf = numberOfWeeks
				this.store.rfConfig.schedule = spotschedule
				this.store.rfConfig.ratecard = {}
				this.store.rfConfig.ratecard_id = null
				this.store.rfDaypartsStandardHandlingUpload = true
				if(this.store.selectionObs.demographics.length === 0) {
					this.store.selectionObs.demographics = this.store.demographics.filter(item => item.id === 48)
				}
				this.pasteOverlayShown = false
			}
		},
	},
	watch: {
		'store.rfConfig.type': {
			deep: true,
			handler() {
				this.store.rfConfig.schedule = []
				this.store.nweeksrf = 1
			}
		},
	},
	mounted() {

	}
}
</script>

<style scoped>

</style>