<template>
	<div @click="$emit('clicked')" class="info" :class="cls">
		<i class="mdi mdi-information-variant" :title="title"></i>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "InfoIcon",
	components: {},
	mixins: [globalMixin],
	props: {
		title: String,
		addclasses: String,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		cls() {
			if(this.addclasses && this.addclasses.length > 0) {
				return this.addclasses;
			}
			return '';
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>