<template>
	<div>
		<table v-if="weeksplitdata && weeksplitdata.length > 0" class="std-tbl">
			<thead>
				<tr>
					<td v-if="showStationCol" data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Station</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Week Number</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">N Spots</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Impacts</td>
<!--					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Reach (Week)</td>-->
					<td v-if="showReachCumulative" data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Reach (Cumulative)</td>
					<td v-if="showReachCumulative" data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Reach Pct (Cumulative)</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Frequency</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Aud</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
					<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="mk in weeksplitdata" :key="mk.id">
					<td v-if="showStationCol" data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.stationName}}</td>
					<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{getDisplayWeek(mk)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.nSpotsCumulative}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.impactsCumulative)}}</td>
<!--					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.reach)}}</td>-->
					<td v-if="showReachCumulative" data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.reachCumulative)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(mk.reachPctCumulative, 1)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(mk.avgFrequencyCumulative, 1)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplayNdp(mk.avgAudCumulative, 0)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{numdisplay(mk.costCumulative)}}</td>
					<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.cpmCumulative}}</td>
				</tr>
			</tbody>
		</table>
		<BlankExcelTwoRows></BlankExcelTwoRows>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlankExcelTwoRows from "@/components/result-tables/BlankExcelTwoRows";

export default {
	name: "RFWeekSplitTable",
	components: {BlankExcelTwoRows},
	props: {
		weeksplitdata: Array,
		stationcol: Boolean,
		showactualweeks: {
			type: String,
			default: 'generic',
		},
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {
		showStationCol() {
			if(this.stationcol) return this.stationcol
			return false
		},
		showReachCumulative() {
			if(this.weeksplitdata[0].reachCumulative) return true
			return false
		},
		firstWeekDisplayName() {
			if(this.showactualweeks === 'actual') return this.weeksplitdata[0].actualWeekId
			else if(this.showactualweeks === 'wcdate') return this.weeksplitdata[0].actualWeekStartDate
			else if(this.showactualweeks === 'wcdatedmy') return this.convertYMDtoDMY(this.weeksplitdata[0].actualWeekStartDate)
			return 'W'+this.weeksplitdata[0].weekNumber
		},
	},
	methods: {
		getDisplayWeek(mk) {
			let out = this.firstWeekDisplayName + ' to '
			if(this.showactualweeks === 'actual') {
				return out + mk.actualWeekId
			}
			else if(this.showactualweeks === 'wcdate') return out + mk.actualWeekEndDate
			else if(this.showactualweeks === 'wcdatedmy') return out + this.convertYMDtoDMY(mk.actualWeekEndDate)
			return out + 'W'+mk.weekNumber
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>